import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import auth from "../services/auth";
import { useNavigate, Link, useBeforeUnload } from "react-router-dom";
import Maps from "./Maps";
import AnimatedPage from "../AnimatedPage";
import "animate.css/animate.min.css";
import _, { random } from "lodash";
import { width } from "@mui/system";
import { TableBody } from "@mui/material";

function Body({ handleUser }) {
  const [formData, setFormData] = useState({
    latitude: "",
    longitude: "",
  });

  let vid = document.querySelector(".videoStart");

  const [user, setUser] = useState();
  const [view, setView] = useState(null);
  const [showCitat, setShowCitat] = useState("Fina recentioner efter uppdrag");
  const [isMobile, setIsMobile] = useState("");
  const [selectedList, setselectedList] = useState("");
  const [shownDest, setShowDest] = useState("");
  const [testX, setTestX] = useState("");
  const [testY, setTestY] = useState("");
  const [destination, setDestination] = useState("");
  const [showMapsList, setShowMapsList] = useState("");
  const [isPlay, setIsPlay] = useState(false);

  let element = document.querySelector(".bosse");
  if (element) {
    element.addEventListener("animationend", () => {
      const data = destination[_.random(0, destination.length - 1)];
      setShowDest(data);
      setTestX(data.latitude);
      setTestY(data.longitude);
      document.body.append(element);
    });
  }

  let history = useNavigate();

  async function handleDeleteDestination(dest) {
    await axios.delete(
      "https://ellinornielsen-backend.onrender.com/api/maps/" + dest._id
    );

    let filterDest = destination.filter((o) => o._id !== dest._id);
    setDestination(filterDest);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
    handleResize();
    getMaps();
  }, []);

  function handleVideoStart() {
    setIsPlay(!isPlay);
    if (!isPlay) {
      vid.play();
    } else {
      vid.pause();
    }
  }

  function togglePlay() {
    setIsPlay(!isPlay);
  }

  async function getMaps() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/maps"
    );
    setDestination(data);
  }

  function handleResize() {
    if (window.screen.width < 1200) {
      return setIsMobile(true);
    } else {
      return setIsMobile(false);
    }
  }

  async function getUser() {
    const user = await auth.getCurrentUser();
    if (user) setUser(user);
    return handleUser(user);
  }

  function handleReset() {
    return setView(null);
  }

  const onLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    handleUser(user);
    return window.location.href;
  };

  const onChange = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    formData[name] = value;
    setFormData({ ...formData });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const map = await axios.post(
        "https://ellinornielsen-backend.onrender.com/api/maps",
        {
          latitude: formData.latitude,
          longitude: formData.longitude,
          destination: formData.destination,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Total>
      <div>
        {user && <Logout onClick={onLogout}>Logga ut</Logout>}

        <Container>
          {user && showMapsList && destination && (
            <EntireMapsList>
              <button
                className="closeButton"
                onClick={() => setShowMapsList(false)}
              >
                X
              </button>
              <table>
                <tr className="trTh">
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Destination</th>
                  <th></th>
                </tr>
                {destination.map((m, i) => (
                  <tbody>
                    <tr className="trTd">
                      <td>{m.latitude}</td>
                      <td>{m.longitude}</td>
                      <td>{m.destination}</td>
                      <td>
                        <button onClick={() => handleDeleteDestination(m)}>
                          X
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </EntireMapsList>
          )}
          {user && (
            <StyledDirection>
              <form onSubmit={handleSubmit}>
                <h3>Karta</h3>
                <input
                  label={"latitude"}
                  name={"latitude"}
                  type={"latitude"}
                  onChange={handleChange}
                  placeholder="Latitude"
                ></input>
                <input
                  label={"longitude"}
                  name={"longitude"}
                  type={"longitude"}
                  onChange={handleChange}
                  placeholder="Longitude"
                ></input>
                <input
                  label={"destination"}
                  name={"destination"}
                  type={"destination"}
                  onChange={handleChange}
                  placeholder="Destination"
                ></input>
                <button onClick={onsubmit}>Skicka</button>
                <button onClick={() => setShowMapsList(true)}>Lista</button>
              </form>
            </StyledDirection>
          )}
        </Container>
        {
          <Container2>
            {!view && (
              <StyledDiv>
                {!isPlay && !isMobile && (
                  <img
                    className="playButton"
                    src="play-button2.png"
                    onClick={() => handleVideoStart()}
                  ></img>
                )}
                <StyledStart
                  className="videoStart"
                  controls={"controls"}
                  muted={false}
                  autoPlay={false}
                  loop={true}
                  src={"ellinorvid2.mp4"}
                  onClick={() => togglePlay()}
                />
              </StyledDiv>
            )}

            {view && (
              <Selected>
                <div className="box">
                  <p className="titel">Ett av mina uppdrag har varit i</p>
                </div>
                <StyledDiv2>
                  <StyledInner className="inne">
                    <StyledMap testX={testX} testY={testY} />
                    <StyledMapsButton>
                      <button onClick={() => handleReset()}>X</button>
                    </StyledMapsButton>
                  </StyledInner>
                </StyledDiv2>
              </Selected>
            )}
            <MapList className="mapList">
              <div
                className="bosse animate__animated animate__bounceInUp"
                style={{
                  animationDuration: "5s",
                  fontSize: 30,
                  marginLeft: "3%",
                  position: "fixed",
                  fontFamily: "Merriweather-Italic",
                  fontWeight: 100,
                  fontStyle: "oblique 2deg",
                  marginTop: "-5%",

                  maxWidth: 250,
                }}
              >
                {view && <ul className="dest">{shownDest.destination}</ul>}
              </div>
            </MapList>
            {view && (
              <EntireList>
                <h1>Mina spelningar</h1>
                <div class="c">
                  <div class="card-home">
                    {destination.map((dest) => {
                      return <span key={dest._id}>{dest.destination}</span>;
                    })}
                    {destination.map((dest) => {
                      return <span key={dest._id}>{dest.destination}</span>;
                    })}
                  </div>
                </div>
              </EntireList>
            )}
            <Social>
              <div className="content">
                <Link to="/om" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Om mig</p>
                    <p className="text">Klicka här för att läsa mer om mig.</p>
                  </div>
                </Link>
                <Link to="/spelningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Min sång</p>
                    <p className="text">
                      Här kan du ta en del av mina tidigare spelningar
                    </p>
                  </div>
                </Link>
                {!user && (
                  <Link to="/bokning" className="link">
                    <div className="card">
                      <div className="icon"></div>
                      <p className="title">Bokning</p>
                      <p className="text">
                        Ta kontakt med mig här, bokning innebär en kommunikation
                        där jag kontaktar er för bäst möjliga förståelse och
                        framträdande
                      </p>
                    </div>
                  </Link>
                )}
                {user && (
                  <Link to="/bokningar" className="link">
                    <div className="card">
                      <div className="icon"></div>
                      <p className="title">Bokningar</p>
                      <p className="text">
                        Ta kontakt med mig här, bokning innebär en kommunikation
                        där jag kontaktar er för bäst möjliga förståelse och
                        framträdande
                      </p>
                    </div>
                  </Link>
                )}
                <Link to className="link">
                  <div className="card" onClick={() => setView(true)}>
                    <div className="icon"></div>
                    <p className="title">Platser</p>
                    <p className="text">Se tidigare platser på mina uppdrag</p>
                  </div>
                </Link>
              </div>
            </Social>
          </Container2>
        }
      </div>
    </Total>
  );
}
export default Body;

const Total = styled.div``;

const JumpingSecond = styled.div`
  position: relative;
  display: grid;
  place-content: center;

  .text {
    color: white;
    margin-top: 60vh;
    margin-left: 50%;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    @media screen and (max-width: 1000px) {
      margin-left: 30vw;
      font-size: 100px;
      margin-top: 40vh;
    }
  }
  .bosse {
    background-color: black;
    height: 3000px;
    width: 3000px;
    margin-top: -400px;
    @media screen and (max-width: 1000px) {
      height: 200vh;
      width: 120vw;
    }
  }
`;
const Container = styled.div`
  display: grid;
  margin-right: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  position: relative;
`;
const Container2 = styled.div`
  height: 1000px;
  display: grid;
  margin-right: 100px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  position: absolute;

  img {
    margin: 30px;
  }
  video {
    position: fixed;
    top: 0%;
  }
`;

const Logout = styled.button`
  z-index: 999;
  width: 150px;
  height: 40px;
  font-size: 25px;
  border-radius: 4px;
  border-left: 2px solid black;
  background-color: rgba(162, 97, 23, 1);
  color: white;
  font-family: "Brush Script MT, Brush Script Std, cursive";
  font-style: italic;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Social = styled.div`
  @media screen and (max-width: 1000px) {
    zoom: 120%;
  }

  .link {
    text-decoration: none;
    position: relative;
    max-width: 200px;
    margin-left: 20px;
  }
  text-decoration: none;
  body {
    width: 100vw;
    background-color: #1d1d1d;
    margin: 0;
    font-family: helvetica;
  }

  .about {
    $cubic: cubic-bezier(0.64, 0.01, 0.07, 1.65);
    $transition: 0.6s $cubic;
    $size: 40px;
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    width: $size;
    height: $size;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;

    .bg_links {
      width: $size;
      height: $size;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#fff, 0.2);
      border-radius: 100%;
      backdrop-filter: blur(5px);
      position: absolute;
    }

    .logo {
      width: $size;
      height: $size;
      z-index: 9;
      background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 10px 7px;
      opacity: 0.9;
      transition: all 1s 0.2s ease;
      bottom: 0;
      right: 0;
    }

    .social {
      opacity: 0;
      right: 0;
      bottom: 0;

      .icon {
        width: 100%;
        height: 100%;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        display: flex;
        transition: all 0.2s ease, background-color 0.4s ease;
        opacity: 0;
        border-radius: 100%;
      }

      &.portfolio {
        transition: all 0.8s ease;

        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
        }
      }

      &.dribbble {
        transition: all 0.3s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
        }
      }

      &.linkedin {
        transition: all 0.8s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
        }
      }
    }

    &:hover {
      width: 105px;
      height: 105px;
      transition: all $transition;

      .logo {
        opacity: 1;
        transition: all 0.6s ease;
      }

      .social {
        opacity: 1;

        .icon {
          opacity: 0.9;
        }

        &:hover {
          background-size: 28px;
          .icon {
            background-size: 65%;
            opacity: 1;
          }
        }

        &.portfolio {
          right: 0;
          bottom: calc(100% - 40px);
          transition: all 0.3s 0s $cubic;

          .icon {
            &:hover {
              background-color: #698fb7;
            }
          }
        }

        &.dribbble {
          bottom: 45%;
          right: 45%;
          transition: all 0.3s 0.15s $cubic;
          .icon {
            &:hover {
              background-color: #ea4c89;
            }
          }
        }

        &.linkedin {
          bottom: 0;
          right: calc(100% - 40px);
          transition: all 0.3s 0.25s $cubic;
          .icon {
            &:hover {
              background-color: #0077b5;
            }
          }
        }
      }
    }
  }

  .wrapper {
    width: 100vw;
    margin: 0 auto;
    height: 400px;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      height: 700px;
    }
  }

  .content {
    max-width: 1024px;
    width: 30%;
    height: 30%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 28%;
    bottom: -5%;
    position: fixed;
    @media screen and (max-width: 1000px) {
      left: 28%;
    }
  }

  @media screen and (max-width: 767px) {
    .content {
      /* padding-top: 300px;
      flex-direction: column; */
    }
  }

  .card {
    width: 50%;
    max-width: 300px;
    min-width: 200px;
    height: 150px;
    background-color: rgba(193, 150, 86, 0.781);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 12px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mic {
      margin-left: -200px;
      margin-top: -90px;
      height: 80px;
      width: 80px;
      position: relative;
    }

    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
      margin-top: -120px;
    }
  }

  .icon {
  }

  .material-icons.md-18 {
    font-size: 12px;
  }
  .material-icons.md-24 {
    font-size: 12px;
  }
  .material-icons.md-36 {
    font-size: 28px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: -40px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 20px;
  }

  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
  }

  .card:hover {
    height: 270px;
  }

  .card:hover .info {
    height: 90%;
  }
  .card:hover .info {
    height: 90%;
  }

  .card:hover .text {
    opacity: 1;
    max-height: 40px;
    margin-top: 0px;
    font-size: 15px;
  }

  .card:hover .icon {
    background-position: -120px;
    margin-top: -40px;
    height: 150px;
  }

  .card:hover .title {
    margin-top: -200px;
    margin-bottom: 60px;
  }

  .card:hover .icon i {
    background: linear-gradient(90deg, #ff7e7e, #ff4848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
  }
`;

const Selected = styled.div`
  .box {
    z-index: 1;
    position: fixed;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 200px;
    width: 400px;
    background: rgba(162, 97, 23, 0.59);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(162, 97, 23, 0.98);
    opacity: 0.8;
    left: 0px;
    top: 180px;
  }

  .titel {
    font-size: 30px;
    left: 10px;
    top: 180px;
    line-height: 58px;
    margin-left: 10px;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    color: #3d3535;
  }

  height: 900px;
  width: 4510px;
  margin-left: 0%;
  margin-top: 0%;
  position: fixed;
`;

const StyledMapsButton = styled.div`
  position: absolute;
  margin-left: 95%;
  margin-top: -47%;
  height: 40px;
  width: 53px;
  button {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    color: white;
  }
`;
const StyledMap = styled(Maps)`
  height: 30px;
  width: 30px;
`;
const StyledDirection = styled.div`
  z-index: 99;
  height: 150px;
  width: 180px;
  right: -100px;
  margin-top: -30vh;
  position: absolute;

  input {
    z-index: 999;
    width: 150px;
    height: 40px;
    font-size: 25px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    right: 10px;
    top: 10px;
    ::placeholder {
      color: white;
    }
  }

  button {
    z-index: 999;
    width: 79px;
    height: 40px;
    font-size: 20px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    right: 10px;
    top: 10px;
  }

  @media screen and (max-width: 391px) {
    margin-left: 1100px;
  }
  h3 {
    float: right;
    margin-right: 100px;
    color: white;
  }
`;
const StyledStart = styled.video`
  object-fit: cover;
  height: 111vh;
  width: 111vw;
  position: absolute;
  /* object-fit: fill; */
  box-shadow: 0 0 55px white;

  @media screen and (max-width: 1000px) {
    object-fit: fill;
    width: 110%;
    height: 50%;
    margin-top: 20%;
  }
`;

const StyledDiv = styled.div`
  position: absolute;

  margin-top: -15%;
  margin-left: 1%;
  height: 710px;
  width: 100%;

  .playButton {
    position: fixed;
    z-index: 99;
    height: 70px;
    width: 70px;
    margin-left: calc(53vw);
    margin-top: calc(10vh);
    -webkit-filter: hue-rotate(45deg);
    filter: hue-rotate(45deg);
  }

  @media screen and (max-width: 491px) {
    margin-left: -480px;
  }
`;
const StyledDiv2 = styled.div`
  margin-top: -200px;
  margin-left: -200px;

  @media screen and (max-width: 1000px) {
    margin-top: 10%;
    margin-left: -8%;
    zoom: 200%;
  }
`;
const StyledInner = styled.div`
  position: absolute;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  height: 710px;
  width: 710px;
  margin-left: 15%;
  margin-top: -2%;
  @media screen and (max-width: 1000px) {
    margin-top: -10%;
    margin-left: 10%;
    zoom: 70%;
  }
`;

const MobileMenu = styled.div`
  body {
    margin: 0;
    padding: 0;

    /* make it look decent enough */
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle {
    display: block;
    position: fixed;
    top: 350px;
    left: 50px;
    margin-right: 10vw;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 120px;
    height: 102px;
    position: absolute;
    top: -7px;
    left: -22px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 82px;
    height: 12px;
    margin-bottom: 15px;
    position: relative;

    background: #737373;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(1px, 1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 180vw;
    height: 200vh;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 52px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .mic {
    height: 200px;
    margin-left: -20px;
    position: fixed;
    margin-top: 10px;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      height: 300px;
    }
  }
`;

const MapList = styled.div``;

const EntireMapsList = styled.div`
  height: 60vh;
  width: 60vw;
  background: rgba(162, 97, 23, 0.59);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(162, 97, 23, 0.98);
  position: absolute;
  z-index: 999;
  left: 24vw;
  top: -25vh;
  overflow: auto;

  .closeButton {
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    height: 60px;
    width: 60px;
    position: absolute;
    color: white;
    right: 10px;
    top: 28px;
    border-radius: 55%;
    margin-top: -3%;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      margin-left: 91vw;
    }
  }

  table {
    width: 100%;
  }
  td:first-of-type {
    margin-left: 5.3vw;
  }

  .trTd {
    display: grid;
    grid-template-columns: 1.6fr 1.2fr 1fr 1fr;
    margin-bottom: 10px;
    border-bottom: 1px solid black;

    button {
      background-color: red;
      color: white;
    }
  }

  .trTh {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 10px;
  }
`;

const EntireList = styled.div`
  position: fixed;
  right: 10px;
  top: 180px;

  h1 {
    position: fixed;
    color: #3d3535;
    right: 30px;
  }

  .c {
    position: relative;
    background: rgba(162, 97, 23, 0.59);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(162, 97, 23, 0.98);
    opacity: 0.8;
    place-items: center;
    height: 500px;
    float: left;
    width: 300px;
    overflow: hidden;
    overflow-y: hidden;
  }

  span {
    min-width: 300px;
    min-height: 40px;
    display: block;
    color: white;
    line-height: 55px;
    margin: 5px;
    font-size: 20px;
    margin-left: 10px;
  }

  .card-home {
    position: absolute;
    top: 0;
    animation: scroll 10s linear 1s infinite;
  }

  @keyframes scroll {
    100% {
      top: -360px;
    } /* top is the number of spans (in this case 8) multiplied by span height (45px as described above)*/
  }
`;