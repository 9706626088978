import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import auth from "../services/auth";
import { useNavigate, Link } from "react-router-dom";
import AnimatedPage from "../AnimatedPage";
import "animate.css/animate.min.css";
import _, { random } from "lodash";

function Spelningar({ itemsProp, items2Prop }) {
  const [pictureList, setpictureList] = useState([]);
  const [itemsNone, setItemsNone] = useState([]);
  const [picture, setPicture] = useState("");
  const [pictureText, setPictureText] = useState("");
  const [pictureTextPreview, setPictureTextPreview] = useState("");
  const [videoTextPreview, setVideoTextPreview] = useState("");
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [user, setUser] = useState();
  const [view, setView] = useState(null);
  const [viewVideo, setViewVideo] = useState(null);
  const [timer, setTimer] = useState("");
  const [isMobile, setIsMobile] = useState("");
  const [musicType, setmusicType] = useState([]);
  const [showMusic, setshowMusic] = useState(false);
  const [showSender, setshowSender] = useState(false);
  const [textBrollop, setTextBrollop] = useState(false);
  const [textDop, setTextDop] = useState(false);
  const [textBegravning, setTextBegravning] = useState(false);
  const [showCitat, setShowCitat] = useState("");
  const [pictureTextAll, setPictureTextAll] = useState("");
  const [pictureTextToShow, setPictureTextToShow] = useState("");
  const [showCitatLista, setShowCitatLista] = useState(false);
  const [citat, setCitat] = useState([]);
  const [citatText, setCitatText] = useState("");
  const [showLåtLista, setShowLåtLista] = useState(false);
  const [musicCategory, setMusicCategory] = useState("Bröllop");
  const [music, setMusic] = useState([]);
  const [musicFilter, setMusicFilter] = useState([]);
  const [test, setTest] = useState("");

  const [formData, setFormData] = useState({
    song: "",
    artist: "",
    category: "Dop",
  });

  const handleTextForm = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
  };

  async function deleteImage(img) {
    try {
      await axios.delete(
        `https://ellinornielsen-backend.onrender.com/api/uploads/${img.filename}`
      );

      let listfirst = items.filter((pic) => pic.filename !== img.filename);
      let listsecond = items2.filter((pic) => pic.filename !== img.filename);

      await axios.post(
        "https://ellinornielsen-backend.onrender.com/api/order",
        listfirst
      );
      await axios.post(
        "https://ellinornielsen-backend.onrender.com/api/ordersecond",
        listsecond
      );

      setItems(listfirst);
      setItems2(listsecond);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleMusicSubmit(e) {
    e.preventDefault();
    try {
      await axios.post(
        "https://ellinornielsen-backend.onrender.com/api/musictext",
        {
          song: formData.song,
          artist: formData.artist,
          category: formData.category,
        }
      );
      getMusicText();
    } catch (error) {
      console.log(error);
    }
  }

  const history = useNavigate();

  function handleReset() {
    setViewVideo(null);
    setPicture(null);
    setPictureText(null);
    setPictureTextPreview(null);
    setVideoTextPreview(null);
    return setView(null);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
    getList();
    getList2();
    handleResize();
    handleTimer();
    getCitat();
    getMusicText();
  }, []);

  let element = document.querySelector(".bosse");

  if (element) {
    element.remove();
  }

  async function handleDeleteCitat(text) {
    await axios.delete(
      "https://ellinornielsen-backend.onrender.com/api/citat/" + text._id
    );
    let data = citat.filter((c) => c._id !== text._id);
    setCitat(data);
  }

  function handleCitatText(e) {
    setCitatText(e.target.value);
  }

  async function getCitat() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/citat"
    );
    setCitat(data);
  }

  async function handlePostCitat() {
    let body = {
      text: citatText,
    };
    if (citatText) {
      try {
        await axios
          .post("https://ellinornielsen-backend.onrender.com/api/citat", body)

          .catch((error) => {
            console.log(error);
          });
        getCitat();
        setCitatText("");
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function getMusicText() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/musictext"
    );
    setMusic(data);
  }

  function handleMusicList(e) {
    setMusicCategory(e);
    const data = music.filter((m) => m.category == e);
    return setMusicFilter(data);
  }

  async function handleDeleteMusic(text) {
    await axios.delete(
      "https://ellinornielsen-backend.onrender.com/api/musictext/" + text._id
    );

    let data = musicFilter.filter((c) => c._id !== text._id);
    let data2 = music.filter((c) => c._id !== text._id);
    return setMusicFilter(data) + setMusic(data2);
  }

  function closeMenu() {
    if (showMusic === true) {
      setshowMusic(false);
    }
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const random = citat[_.random(0, citat.length - 1)];
  //     setShowCitat(random);
  //   }, 15100);
  //   return () => clearInterval(interval);
  // }, []);

  function listSelected(type) {
    setView(null);
    setViewVideo(null);

    setshowMusic(true);
    if (type === "Bröllop") {
      setTextBrollop(true);
      setTextDop(false);
      setTextBegravning(false);
      return handleMusicList("Bröllop");
    }

    if (type === "Dop") {
      setTextDop(true);
      setTextBrollop(false);
      setTextBegravning(false);
      return handleMusicList("Dop");
    }

    if (type === "Begravning") {
      setTextBegravning(true);
      setTextBrollop(false);
      setTextDop(false);
      return handleMusicList("Begravning");
    }
  }

  function handleResize() {
    if (window.screen.width < 1200) {
      return setIsMobile(true);
    } else {
      return setIsMobile(false);
    }
  }

  function handleTimer() {
    const interval = setInterval(() => {
      return setTimer(true);
    }, 2100);
  }

  async function getFolder() {
    let list = [];
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/uploads"
    );

    const Picture = data.map((m) => {
      let firstCheck = false;
      let secondCheck = false;

      let first = items.map((item) => {
        if (m.filename == item.filename) {
          firstCheck = true;
        }
      });

      let second = items2.map((item) => {
        if (m.filename == item.filename) {
          secondCheck = true;
        }
      });

      if (!firstCheck && !secondCheck) {
        list.push(m);
      }
      return m;
    });

    return setItemsNone(list);
  }
  async function getList() {
    const user = await auth.getCurrentUser();
    if (user) {
      const { data } = await axios.get(
        "https://ellinornielsen-backend.onrender.com/api/order"
      );
      return setItems(data[0].image);
    }
  }

  async function getList2() {
    const user = await auth.getCurrentUser();
    if (user) {
      const { data } = await axios.get(
        "https://ellinornielsen-backend.onrender.com/api/ordersecond"
      );
      return setItems2(data[0].image);
    }
  }

  async function getUser() {
    const user = await auth.getCurrentUser();

    if (user) return setUser(user);
  }

  function getTextForPicture(img) {
    if (img.text != "null") {
      return setPictureTextToShow(img.text);
    } else {
      setPictureTextToShow("");
    }
  }

  function handleReset() {
    setViewVideo(null);
    setPictureTextToShow(null);
    setPictureTextPreview(null);
    setVideoTextPreview(null);

    return setView(null);
  }

  function totalReset() {
    if (view) {
      return setView(null);
    }
  }

  const onLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  const handlePicture = (e) => {
    console.log("test", e.target.files[0]);
    if (
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpeg"
    ) {
      setVideoTextPreview(null);
      setPictureTextPreview(URL.createObjectURL(e.target.files[0]));
    }

    if (e.target.files[0].type == "video/mp4") {
      setPictureTextPreview(null);
      setVideoTextPreview(URL.createObjectURL(e.target.files[0]));
    }

    setPicture(e.target.files[0]);
  };

  const handlePictureText = (e) => {
    setPictureText(e.target.value);
  };

  const onChange = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("photo", picture);
    formdata.append("text", pictureText);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    if (picture) {
      try {
        await axios
          .post(
            "https://ellinornielsen-backend.onrender.com/api/uploads",
            formdata,
            config
          )
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    getFolder();
    setshowSender(null);
    setPicture(null);
    setPictureText(null);
    setPictureTextPreview(null);
    setVideoTextPreview(null);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    )
      return;

    let add,
      active = items,
      complete = items2,
      none = itemsNone;

    if (result.source.droppableId === "Images") {
      add = active[result.source.index];
      active.splice(result.source.index, 1);
    } else if (result.source.droppableId === "Images2") {
      add = complete[result.source.index];
      complete.splice(result.source.index, 1);
    } else if (result.source.droppableId === "none") {
      add = none[result.source.index];
      none.splice(result.source.index, 1);
    }

    if (result.destination.droppableId === "Images") {
      active.splice(result.destination.index, 0, add);
    }
    if (result.destination.droppableId === "Images2") {
      complete.splice(result.destination.index, 0, add);
    }
    if (result.destination.droppableId === "none") {
      none.splice(result.destination.index, 0, add);
    }

    setItemsNone(none);
    setItems2(complete);
    setItems(active);
    return (
      axios.post(
        "https://ellinornielsen-backend.onrender.com/api/order",
        active
      ),
      axios.post(
        "https://ellinornielsen-backend.onrender.com/api/ordersecond",
        complete
      )
    );
  }

  return (
    <Total onClick={() => totalReset()}>
      {showCitatLista && user && (
        <EntireCitatList>
          <div>
            <button
              className="closeButton"
              onClick={() => setShowCitatLista(false)}
            >
              X
            </button>
            <div className="list">
              <table>
                <thead>
                  <tr className="trTh">
                    <th>Citat Text</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {citat.map((t, i) => {
                    return (
                      <tr key={i} className="trTd">
                        <td>{t?.text}</td>
                        <td>
                          <button onClick={() => handleDeleteCitat(t)}>
                            X
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="text">
              <textarea
                className="citatText"
                label={"text"}
                name={"text"}
                type={"text area"}
                placeholder={"Skriv Citattext här..."}
                onChange={handleCitatText}
                value={citatText}
              />
              <button className="skicka" onClick={() => handlePostCitat()}>
                Skicka
              </button>
            </div>
          </div>
        </EntireCitatList>
      )}
      {showLåtLista && user && (
        <div className="category">
          <button onClick={() => handleMusicList("Bröllop")}>Bröllop</button>
          <button onClick={() => handleMusicList("Dop")}>Dop</button>
          <button onClick={() => handleMusicList("Begravning")}>
            Begravning
          </button>
        </div>
      )}
      {showLåtLista && user && (
        <EntireLåtList>
          <div>
            <button
              className="closeButton"
              onClick={() => setShowLåtLista(false)}
            >
              X
            </button>
            <div className="list">
              <table>
                <thead>
                  <tr className="trTh">
                    <th>{musicCategory}Låtar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {musicFilter.map((m, i) => {
                    return (
                      <tr key={i} className="trTd">
                        <td>{m?.song + ":" + m?.artist}</td>
                        <td>
                          <button onClick={() => handleDeleteMusic(m)}>
                            X
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="text">
              <form onSubmit={handleMusicSubmit}>
                <input
                  label={"song"}
                  name={"song"}
                  type={"input"}
                  placeholder={"Låt..."}
                  onChange={handleTextForm}
                  value={formData.låt}
                />
                <input
                  label={"artist"}
                  name={"artist"}
                  type={"input"}
                  placeholder={"Artist..."}
                  onChange={handleTextForm}
                  value={formData.artist}
                />
                <select
                  name="category"
                  onChange={handleTextForm}
                  value={formData.category}
                >
                  <option defaultValue="Dop">Dop</option>
                  <option value="Bröllop">Bröllop</option>
                  <option value="Begravning">Begravning</option>
                </select>
                <button className="skicka" onClick={() => onsubmit}>
                  Skicka
                </button>
              </form>
            </div>
          </div>
        </EntireLåtList>
      )}

      {!timer && (
        <JumpingSecond>
          <div className="bosse animate__animated animate__fadeOut">
            <h3 className="text"> Spelningar</h3>
          </div>
        </JumpingSecond>
      )}
      <div>
        {user && <Logout onClick={onLogout}>Logga ut</Logout>}

        {user && (
          <button
            className="laddaUpp"
            onClick={() =>
              setshowSender(true) +
              setShowCitatLista(false) +
              setShowLåtLista(false)
            }
          >
            Ladda upp
          </button>
        )}

        {user && (
          <button className="LaddaOm" onClick={() => getFolder()}>
            Ladda Om
          </button>
        )}
        {user && (
          <button
            className="citatLista"
            onClick={() =>
              setShowCitatLista(true) +
              setShowLåtLista(false) +
              setshowSender(false)
            }
          >
            Citat
          </button>
        )}
        {user && (
          <button
            className="låtLista"
            onClick={() =>
              handleMusicList("Bröllop") +
              setShowLåtLista(true) +
              setShowCitatLista(false) +
              setshowSender(false)
            }
          >
            Låtar
          </button>
        )}
        {showSender && user && (
          <Sender>
            <div className="around">
              <button
                className="closeButton"
                onClick={() =>
                  setshowSender(null) +
                  getFolder() +
                  setPictureTextPreview(null) +
                  setVideoTextPreview(null) +
                  setPictureText("")
                }
              >
                X
              </button>
              <form onSubmit={onChange}>
                {pictureTextPreview && (
                  <img className="preview" src={pictureTextPreview} />
                )}
                {videoTextPreview && (
                  <StyledStart
                    className="videoStart"
                    controls={"controls"}
                    muted={false}
                    autoPlay={false}
                    loop={true}
                    src={videoTextPreview}
                  />
                )}
                <textarea
                  className="pictureText"
                  label={"text"}
                  name={"text"}
                  type={"text area"}
                  placeholder={"Skriv bildtext här..."}
                  onChange={handlePictureText}
                  value={pictureText}
                />
                <div className="buttons">
                  <button className="select">
                    <input onChange={handlePicture} type="file" />
                  </button>
                  <button className="skicka" onClick={(e) => onChange}>
                    Skicka
                  </button>
                </div>
              </form>
            </div>
          </Sender>
        )}
        {user && (
          <Container>
            <Social>
              <div className="content">
                <Link to="/om" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Om mig</p>
                    <p className="text">Klicka här för att läsa mer om mig.</p>
                  </div>
                </Link>

                <Link to="/bokningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Bokningar</p>
                    <p className="text">
                      Ta kontakt med mig här, bokning innebär en kommunikation
                      där jag kontaktar er för bäst möjliga förståelse och
                      framträdande
                    </p>
                  </div>
                </Link>
              </div>
            </Social>
            {!showSender && (
              <DragDropContext
                onDragEnd={handleOnDragEnd}
                className="allElements"
              >
                {itemsNone && !showCitatLista && !showLåtLista && (
                  <Droppable droppableId="none">
                    {(provided) => (
                      <span
                        className="item"
                        id="none"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {provided.placeholder}
                        {itemsNone.map((img, index, array) => (
                          <span key={index}>
                            <StyledUl>
                              <Draggable
                                key={index}
                                draggableId={img?._id}
                                index={index}
                              >
                                {(provided) => (
                                  <StyledUl
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    {img.mimetype == "image/png" && (
                                      <Picture1
                                        key={index}
                                        className="item"
                                        src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                        alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                      />
                                    )}
                                    {img.mimetype == "image/jpeg" && (
                                      <Picture1
                                        className="item"
                                        key={index}
                                        src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                        alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                      />
                                    )}

                                    {img.mimetype == "video/mp4" && (
                                      <Video
                                        className="item"
                                        width="300px"
                                        height="300px"
                                        controls="controls"
                                      >
                                        <source
                                          src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                          type="video/mp4"
                                        />
                                      </Video>
                                    )}
                                  </StyledUl>
                                )}
                              </Draggable>
                            </StyledUl>
                          </span>
                        ))}
                      </span>
                    )}
                  </Droppable>
                )}
                {itemsNone && !showCitatLista && !showLåtLista && (
                  <Droppable droppableId="Images">
                    {(provided) => (
                      <span
                        className="item"
                        id="Container"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {provided.placeholder}
                        {items.map((img, index, array) => (
                          <span key={index}>
                            <StyledUl>
                              <Draggable
                                key={img._id}
                                draggableId={img._id}
                                index={index}
                              >
                                {(provided) => (
                                  <StyledUl
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    {img.mimetype == "image/png" && (
                                      <div>
                                        <button
                                          className="deleteButton"
                                          onClick={() => deleteImage(img)}
                                        >
                                          X
                                        </button>
                                        <Picture1
                                          className="item"
                                          key={index}
                                          src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                          alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                        />
                                      </div>
                                    )}
                                    {img.mimetype == "image/jpeg" && (
                                      <div>
                                        <button
                                          className="deleteButton"
                                          onClick={() => deleteImage(img)}
                                        >
                                          X
                                        </button>
                                        <Picture1
                                          className="item"
                                          key={index}
                                          src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                          alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                        />
                                      </div>
                                    )}

                                    {img.mimetype == "video/mp4" && (
                                      <div>
                                        <button
                                          className="deleteButton"
                                          onClick={() => deleteImage(img)}
                                        >
                                          X
                                        </button>
                                        <Video
                                          className="item"
                                          width="300px"
                                          height="300px"
                                          controls="controls"
                                        >
                                          <source
                                            src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                            type="video/mp4"
                                          />
                                        </Video>
                                      </div>
                                    )}
                                  </StyledUl>
                                )}
                              </Draggable>
                            </StyledUl>
                          </span>
                        ))}
                      </span>
                    )}
                  </Droppable>
                )}

                {itemsNone && !showCitatLista && !showLåtLista && (
                  <Droppable droppableId="Images2">
                    {(provided) => (
                      <span
                        className="item"
                        id="Container2"
                        ref={provided.innerRef}
                        {...provided.droppableProp}
                      >
                        {provided.placeholder}
                        {items2.map((img, index, array) => (
                          <span key={index}>
                            <StyledUl>
                              <Draggable
                                key={img._id}
                                draggableId={img._id}
                                index={index}
                              >
                                {(provided) => (
                                  <StyledUl
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    {img.mimetype == "image/png" && (
                                      <div>
                                        <button
                                          className="deleteButton"
                                          onClick={() => deleteImage(img)}
                                        >
                                          X
                                        </button>
                                        <Picture1
                                          className="item"
                                          key={index}
                                          src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                          alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                        />
                                      </div>
                                    )}
                                    {img.mimetype == "image/jpeg" && (
                                      <div>
                                        <button
                                          className="deleteButton"
                                          onClick={() => deleteImage(img)}
                                        >
                                          X
                                        </button>
                                        <Picture1
                                          className="item"
                                          key={index}
                                          src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                          alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                        />
                                      </div>
                                    )}
                                    {img.mimetype == "video/mp4" && (
                                      <div>
                                        <button
                                          className="deleteButton"
                                          onClick={() => deleteImage(img)}
                                        >
                                          X
                                        </button>
                                        <Video
                                          className="item"
                                          width="300px"
                                          height="300px"
                                          controls="controls"
                                        >
                                          <source
                                            src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                            type="video/mp4"
                                          />
                                        </Video>
                                      </div>
                                    )}
                                  </StyledUl>
                                )}
                              </Draggable>
                            </StyledUl>
                          </span>
                        ))}
                      </span>
                    )}
                  </Droppable>
                )}
              </DragDropContext>
            )}
          </Container>
        )}
        {!user && (
          <Container2>
            <Sidemenu>
              <div className="menu">
                <input
                  type="checkbox"
                  id="menu-collapsed"
                  name="menu-collapsed"
                />
                <div className="menu-content">
                  <h2>Låtförslag</h2>
                  <ul>
                    <li onClick={() => listSelected("Begravning")}>
                      Begravning
                    </li>
                    <li onClick={() => listSelected("Dop")}>Dop</li>
                    <li onClick={() => listSelected("Bröllop")}>Bröllop</li>
                  </ul>
                </div>
                <div className="menu-switch">
                  <label className="collapse" htmlFor="menu-collapsed">
                    <img src="plectrum.png" height={60} width={60}></img>
                  </label>
                  <label className="rise" htmlFor="menu-collapsed">
                    <img src="plectrum.png" height={60} width={60}></img>
                  </label>
                </div>
              </div>
            </Sidemenu>

            {!isMobile && (
              <Social>
                <div className="content">
                  <Link to="/om" className="link">
                    <div className="card">
                      <div className="icon"></div>
                      <p className="title">Om mig</p>
                      <p className="text">
                        Klicka här för att läsa mer om mig.
                      </p>
                    </div>
                  </Link>

                  <Link to="/bokning" className="link">
                    <div className="card">
                      <div className="icon"></div>
                      <p className="title">Bokning</p>
                      <p className="text">
                        Ta kontakt med mig här, bokning innebär en kommunikation
                        där jag kontaktar er för bäst möjliga förståelse och
                        framträdande
                      </p>
                    </div>
                  </Link>
                </div>
              </Social>
            )}
            {isMobile && (
              <Social>
                <div className="content">
                  <Link to="/om" className="link">
                    <div className="card">
                      <div className="icon"></div>
                      <p className="title">Om mig</p>
                      <p className="text">
                        Klicka här för att läsa mer om mig.
                      </p>
                    </div>
                  </Link>

                  <Link to="/bokning" className="link">
                    <div className="card">
                      <div className="icon"></div>
                      <p className="title">Bokning</p>
                      <p className="text">
                        Ta kontakt med mig här, bokning innebär en kommunikation
                        där jag kontaktar er för bäst möjliga förståelse och
                        framträdande
                      </p>
                    </div>
                  </Link>
                </div>
              </Social>
            )}
            {view && !showMusic && (
              <Selected2>
                <button onClick={() => handleReset()}>X</button>
                {view && <img onClick={() => handleReset()} src={view} />}
                {view && pictureTextToShow && <p>{pictureTextToShow}</p>}
              </Selected2>
            )}

            {viewVideo && !showMusic && (
              <Selected>
                <button onClick={() => handleReset()}>X</button>
                {viewVideo && (
                  <video width="800px" height="64%" controls="controls">
                    <source src={viewVideo} type="video/mp4" />
                  </video>
                )}
                {viewVideo && pictureTextToShow && <p>{pictureTextToShow}</p>}
              </Selected>
            )}

            {showMusic && (
              <div onClick={() => closeMenu()}>
                {textBrollop && <h1 className="brollopText">Bröllop</h1>}
                {textDop && <h1 className="dopText">Dop</h1>}
                {textBegravning && (
                  <h1 className="begravningText">Begravning</h1>
                )}
                <SongListBrollop>
                  <button onClick={() => handleReset()}>X</button>
                  {musicFilter.map((m, index) => (
                    <div key={index}>
                      <p>{`${m?.song} : ${m?.artist}`}</p>
                    </div>
                  ))}
                </SongListBrollop>
              </div>
            )}

            {
              <div>
                {!view && !viewVideo && !showMusic && (
                  <div>
                    {itemsProp.map((img, index, array) => (
                      <span key={index} className="item">
                        <StyledUl>
                          {img.mimetype == "image/png" && (
                            <Picture1
                              onClick={() =>
                                setView(
                                  `https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`
                                ) +
                                window.scrollTo(0, 0) +
                                getTextForPicture(img)
                              }
                              key={index}
                              src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                              alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                            />
                          )}
                          {img.mimetype == "image/jpeg" && (
                            <Picture1
                              onClick={() =>
                                setView(
                                  `https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`
                                ) +
                                window.scrollTo(0, 0) +
                                getTextForPicture(img)
                              }
                              key={index}
                              src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                              alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                            />
                          )}

                          {img.mimetype == "video/mp4" && (
                            <div
                              className="combined"
                              style={{
                                zIndex: 999,
                                height: 300,
                                width: 300,
                                marginBottom: 80,
                              }}
                              onClick={() =>
                                setViewVideo(
                                  `https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`
                                ) +
                                window.scrollTo(0, 0) +
                                getTextForPicture(img)
                              }
                            >
                              {/* <img
                                className="playButton"
                                src="play-button2.png"
                              ></img> */}
                              <Video
                                width="300px"
                                height="300px"
                                muted={true}
                                style={{ zIndex: 2 }}
                                onClick={() =>
                                  setViewVideo(
                                    `https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`
                                  ) +
                                  window.scrollTo(0, 0) +
                                  getTextForPicture(img)
                                }
                              >
                                <source
                                  src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                                  type="video/mp4"
                                />
                              </Video>
                            </div>
                          )}
                        </StyledUl>
                      </span>
                    ))}
                  </div>
                )}
              </div>
            }
            {!view && !viewVideo && !showMusic && (
              <div>
                {items2Prop.map((img, index, array) => (
                  <span key={index} className="item">
                    <StyledUl>
                      {img.mimetype == "image/png" && (
                        <Picture1
                          onClick={() =>
                            setView(
                              `https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`
                            ) +
                            window.scrollTo(0, 0) +
                            getTextForPicture(img)
                          }
                          key={index}
                          src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                          alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                        />
                      )}
                      {img.mimetype == "image/jpeg" && (
                        <Picture1
                          onClick={() =>
                            setView(
                              `https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`
                            ) +
                            window.scrollTo(0, 0) +
                            getTextForPicture(img)
                          }
                          key={index}
                          src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                          alt={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                        />
                      )}
                      {img.mimetype == "video/mp4" && (
                        <div
                          className="combined"
                          style={{
                            zIndex: 999,
                            height: 300,
                            width: 300,
                            marginBottom: 80,
                          }}
                          onClick={() =>
                            setViewVideo(
                              `https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`
                            ) +
                            window.scrollTo(0, 0) +
                            getTextForPicture(img)
                          }
                        >
                          {/* <img
                            className="playButton"
                            src="play-button2.png"
                          ></img> */}

                          <Video
                            width="300px"
                            height="300px"
                            muted={true}
                            style={{ zIndex: 2 }}
                          >
                            <source
                              src={`https://ellinornielsen-backend.onrender.com/api/uploads/${img["filename"]}`}
                              type="video/mp4"
                            />
                          </Video>
                        </div>
                      )}
                    </StyledUl>
                  </span>
                ))}
              </div>
            )}
          </Container2>
        )}
        {!isMobile && (
          <Jumping>
            <div className="bosse animate__animated animate__bounceInDown animate__infinite">
              <h3> {showCitat}</h3>
            </div>
          </Jumping>
        )}
      </div>
    </Total>
  );
}
export default Spelningar;

const Jumping = styled.div`
  bottom: 80px;
  position: fixed;
  right: 30px;
  display: grid;
  place-content: center;
  z-index: 99;
  @media screen and (max-width: 391px) {
    margin-left: 980px;
  }

  h3 {
    display: grid;
    place-content: center;
    border-radius: 32px;
    height: 120%;
    max-height: 500px;
    width: 15em;
    background-color: white;
    opacity: 0.5;
    color: black;
    padding: 20px;
  }
  div {
    animation-duration: 20s;
  }
`;

const Total = styled.div`
  .category {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 14.2vw;
    top: 0vh;
    position: relative;
    button {
      width: 150px;
      height: 40px;
      font-size: 25px;
      border-radius: 4px;
      border-left: 2px solid black;
      background-color: rgba(162, 97, 23, 1);
      color: white;
      font-family: "Brush Script MT, Brush Script Std, cursive";
      font-style: italic;
    }
  }

  .laddaUpp {
    width: 150px;
    height: 40px;
    font-size: 25px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    position: absolute;
    right: 10px;
    top: 120px;
  }

  .LaddaOm {
    width: 150px;
    height: 40px;
    font-size: 25px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    position: absolute;
    right: 10px;
    top: 165px;
  }

  .citatLista {
    width: 150px;
    height: 40px;
    font-size: 25px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    position: absolute;
    right: 10px;
    top: 210px;
  }

  .låtLista {
    width: 150px;
    height: 40px;
    font-size: 25px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    position: absolute;
    right: 10px;
    top: 255px;
  }

  .brollopText {
    position: absolute;
    color: black;
    margin-left: 15vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.2;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .dopText {
    position: absolute;
    color: black;
    margin-left: 20vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.2;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .begravningText {
    color: black;
    position: absolute;
    margin-left: 7vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.2;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
`;
const JumpingSecond = styled.div`
  position: relative;
  display: grid;
  place-content: center;

  .text {
    color: white;
    margin-top: 60vh;
    margin-left: 50%;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    @media screen and (max-width: 1000px) {
      margin-left: 40vw;
      font-size: 100px;
      margin-top: 40vh;
    }
  }
  .bosse {
    background-color: black;
    height: 3000px;
    width: 3000px;
    margin-top: -400px;
    animation-duration: 3s;
    @media screen and (max-width: 1000px) {
      height: 200vh;
      width: 120vw;
    }
  }
`;

const Picture1 = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0 0 50px gray;
  background-color: white;
  position: relative;
  :hover {
    transition: 1s;
    height: 320px;
    width: 320px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
  :not(:hover) {
    transition: 1s;
    height: 300px;
    width: 300px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
`;
const Picture2 = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0 0 50px gray;
  background-color: white;
  position: relative;
  :hover {
    transition: 1s;
    height: 320px;
    width: 320px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
  :not(:hover) {
    transition: 1s;
    height: 300px;
    width: 300px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
`;

const Container = styled.div`
  height: 200px;
  display: grid;
  margin-right: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;

  @media screen and (max-width: 1000px) {
    margin-left: 80px;
  }
`;
const Container2 = styled.div`
  height: 200px;
  display: grid;
  /* margin-right: 100px; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  z-index: 97;
  .item:last-child {
    img {
      margin-bottom: 500px;
    }
  }

  @media screen and (max-width: 1000px) {
    margin-left: -3%;
  }

  img {
    margin: 30px;
  }
  video {
    margin: 30px;
  }

  .playButton {
    z-index: 999;
    position: absolute;
    margin-left: 9vw;
    margin-top: 15vh;
    height: 100px;
    width: 100px;
    opacity: 1;
    -webkit-filter: hue-rotate(45deg);
    filter: hue-rotate(47deg);
    @media screen and (max-width: 1000px) {
      margin-top: 7vh;
      margin-left: 14vw;
    }
  }

  .noAdmin {
    img {
      margin-bottom: 200px;
      height: 300px;
      width: 300px;
      @media screen and (max-width: 491px) {
        width: 200px;
        height: 200px;
      }
    }
    video {
      margin-bottom: 200px;
      height: 300px;
      width: 300px;
      @media screen and (max-width: 491px) {
        width: 200px;
        height: 200px;
      }
    }
  }
`;

const StyledUl = styled.ul`
  /* height: 200px;
  width: 100px; */
  .deleteButton {
    float: right;
    background-color: red;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    border: 1px solid white;
  }

  .combined {
    z-index: 999;
    :hover .playButton {
      transition: 2s;
      height: 120px;
      width: 120px;
    }
    :not(:hover) {
      transition: 2s;
      height: 100px;
      width: 100px;
    }
  }

  position: relative;
`;

const Video = styled.video`
  width: 300px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0 0 50px gray;
  background-color: white;
  position: relative;

  :hover {
    transition: 1s;
    height: 320px;
    width: 320px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
  :not(:hover) {
    transition: 1s;
    height: 300px;
    width: 300px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
`;

const Logout = styled.button`
  width: 150px;
  height: 40px;
  font-size: 25px;
  border-radius: 4px;
  border-left: 2px solid black;
  background-color: rgba(162, 97, 23, 1);
  color: white;
  font-family: "Brush Script MT, Brush Script Std, cursive";
  font-style: italic;
  position: absolute;
  right: 10px;
  top: 10px;
`;
const Sender = styled.div`
  height: 420px;
  width: 44vw;
  margin-left: 30vw;
  top: 30px;
  position: absolute;
  z-index: 999;

  .around {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    background-color: #ffffff76;
    max-height: 450px;
  }

  .select {
    width: 22vw;
    height: 40px;
    border-radius: 4px;
    border-right: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-size: 15px;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }

  .skicka {
    width: 22vw;
    height: 40px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-size: 15px;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }

  .pictureText {
    position: relative;
    margin-top: 0vh;
    width: 43.6vw;
    height: 200px;
    border-radius: 5px;
    /* box-shadow: 0 0 12px black; */
    resize: none;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }

  .preview {
    position: relative;
    height: 420px;
    width: 44vw;
    object-fit: fill;
    margin-top: 0vh;
    margin-left: 0vw;
  }

  .closeButton {
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    height: 60px;
    width: 60px;
    position: absolute;
    color: white;
    margin-left: 42vw;
    border-radius: 55%;
    margin-top: -3%;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      margin-left: 91vw;
    }
  }
`;
const Social = styled.div`
  z-index: 98;
  @media screen and (max-width: 1000px) {
    zoom: 150%;
  }

  .link {
    text-decoration: none;
    position: relative;
    max-width: 200px;
    margin-left: 20px;
  }
  text-decoration: none;
  body {
    width: 100vw;
    background-color: #1d1d1d;
    margin: 0;
    font-family: helvetica;
  }

  .about {
    $cubic: cubic-bezier(0.64, 0.01, 0.07, 1.65);
    $transition: 0.6s $cubic;
    $size: 40px;
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    width: $size;
    height: $size;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;

    .bg_links {
      width: $size;
      height: $size;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#fff, 0.2);
      border-radius: 100%;
      backdrop-filter: blur(5px);
      position: absolute;
    }

    .logo {
      width: $size;
      height: $size;
      z-index: 9;
      background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 10px 7px;
      opacity: 0.9;
      transition: all 1s 0.2s ease;
      bottom: 0;
      right: 0;
    }

    .social {
      opacity: 0;
      right: 0;
      bottom: 0;

      .icon {
        width: 100%;
        height: 100%;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        display: flex;
        transition: all 0.2s ease, background-color 0.4s ease;
        opacity: 0;
        border-radius: 100%;
      }

      &.portfolio {
        transition: all 0.8s ease;

        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
        }
      }

      &.dribbble {
        transition: all 0.3s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
        }
      }

      &.linkedin {
        transition: all 0.8s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
        }
      }
    }

    &:hover {
      width: 105px;
      height: 105px;
      transition: all $transition;

      .logo {
        opacity: 1;
        transition: all 0.6s ease;
      }

      .social {
        opacity: 1;

        .icon {
          opacity: 0.9;
        }

        &:hover {
          background-size: 28px;
          .icon {
            background-size: 65%;
            opacity: 1;
          }
        }

        &.portfolio {
          right: 0;
          bottom: calc(100% - 40px);
          transition: all 0.3s 0s $cubic;

          .icon {
            &:hover {
              background-color: #698fb7;
            }
          }
        }

        &.dribbble {
          bottom: 45%;
          right: 45%;
          transition: all 0.3s 0.15s $cubic;
          .icon {
            &:hover {
              background-color: #ea4c89;
            }
          }
        }

        &.linkedin {
          bottom: 0;
          right: calc(100% - 40px);
          transition: all 0.3s 0.25s $cubic;
          .icon {
            &:hover {
              background-color: #0077b5;
            }
          }
        }
      }
    }
  }

  .wrapper {
    width: 100vw;
    margin: 0 auto;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      height: 700px;
    }
  }

  .content {
    max-width: 1024px;
    width: 30%;
    height: 30%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 28%;
    bottom: -5%;
    position: fixed;
    @media screen and (max-width: 1000px) {
      left: 28%;
    }
  }

  @media screen and (max-width: 767px) {
    .content {
      /* padding-top: 300px;
      flex-direction: column; */
    }
  }

  .card {
    width: 50%;
    max-width: 300px;
    min-width: 200px;
    height: 150px;
    background-color: rgba(193, 150, 86, 0.781);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 12px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mic {
      margin-left: -200px;
      margin-top: -90px;
      height: 80px;
      width: 80px;
      position: relative;
    }

    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
      margin-top: -120px;
    }
  }

  .icon {
  }

  .material-icons.md-18 {
    font-size: 12px;
  }
  .material-icons.md-24 {
    font-size: 12px;
  }
  .material-icons.md-36 {
    font-size: 28px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: -40px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 20px;
  }

  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
  }

  .card:hover {
    height: 270px;
  }

  .card:hover .info {
    height: 90%;
  }
  .card:hover .info {
    height: 90%;
  }

  .card:hover .text {
    opacity: 1;
    max-height: 40px;
    margin-top: 0px;
    font-size: 15px;
  }

  .card:hover .icon {
    background-position: -120px;
    margin-top: -40px;
    height: 150px;
  }

  .card:hover .title {
    margin-top: -200px;
    margin-bottom: 60px;
  }

  .card:hover .icon i {
    background: linear-gradient(90deg, #ff7e7e, #ff4848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
  }
`;

const Selected = styled.div`
  position: relative;
  height: 700px;
  width: 700px;
  left: 5%;
  top: -30%;
  margin-right: 50vw;
  margin-left: 20%;
  img {
    height: 100%;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  }
  video {
    margin-top: -8%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    @media screen and (max-width: 1000px) {
      width: 90vw;
      height: auto;
      max-width: none;
      max-height: 100vh;
      object-fit: contain;
      margin-top: -17vh;
      margin-left: 25%;
    }
  }
  p {
    margin-top: -2vh;
    margin-left: 2vw;
    width: 800px;
    max-height: 200px;
    overflow: auto;
    background-color: #ffffff76;
    color: black;
    ::-webkit-scrollbar {
      width: 1em;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(162, 97, 23, 1);
      outline: 1px solid slategrey;
    }
  }

  button {
    position: absolute;
    margin-left: 112%;
    margin-top: -10%;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #a26117;
    border: 0px;
    color: white;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      margin-top: -19vh;
      font-size: 30px;
      height: 80px;
      width: 80px;
      margin-left: 97vw;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 50vw;
    height: auto;
    max-width: none;
    max-height: 100vh;
    object-fit: contain;
    margin-top: 30vh;
    margin-left: -10%;
  }
`;

const Selected2 = styled.div`
  position: relative;
  max-height: 600px;
  width: 600px;
  aspect-ratio: 0.0001;
  left: 5%;
  top: -40%;
  margin-right: 50vw;
  margin-left: 20%;
  img {
    height: 100%;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    @media screen and (max-width: 1000px) {
      height: 80%;
      width: 80%;
      margin-left: 22vw;
    }
  }
  video {
    margin-top: -8%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  }

  p {
    margin-top: -70vh;
    width: 400px;
    position: absolute;
    margin-left: 44vw;
    max-height: 600px;
    overflow: auto;
    background-color: #ffffff76;
    color: black;
    ::-webkit-scrollbar {
      width: 1em;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(162, 97, 23, 1);
      outline: 1px solid slategrey;
    }
  }

  button {
    position: absolute;
    margin-left: 99%;
    margin-top: 1%;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    color: white;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      font-size: 30px;
      margin-left: 90%;
      margin-top: -1vh;
      height: 100px;
      width: 100px;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 150vw;
    height: auto;
    max-width: none;
    max-height: 100vh;
    object-fit: contain;
    margin-top: 30vh;
    margin-left: -10%;
  }
`;
const MobileMenu = styled.div`
  body {
    margin: 0;
    padding: 0;

    /* make it look decent enough */
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle {
    display: block;
    position: fixed;
    top: 350px;
    left: 50px;
    margin-right: 10vw;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 120px;
    height: 102px;
    position: absolute;
    top: -7px;
    left: -22px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 82px;
    height: 12px;
    margin-bottom: 15px;
    position: relative;

    background: #737373;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(1px, 1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 180vw;
    height: 200vh;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 52px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }
`;

const Sidemenu = styled.div`
  z-index: 99;
  @media screen and (max-width: 1000px) {
    zoom: 210%;
    margin-top: 10%;
    display: none;
  }
  position: absolute;
  margin: 0;
  padding: 0;
  border: 0;
  width: 20%;
  height: 100%;
  font-family: Merriweather-Light;
  color: rgb(79, 41, 6);

  .menu {
    .menu-content {
      -webkit-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -ms-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      float: left;
      width: 12em;
      height: 50%;
      width: 150px;
      margin-top: 3em;

      h2 {
        font-family: Merriweather-Light;
        font-weight: normal;
        margin-left: 1em;
        color: black;
        margin-left: 38px;
        border-bottom: 1px solid black;
      }
      ul {
        list-style: none;
        max-height: 200px;
      }
      ul li:hover {
        font-size: 25px;
      }
    }

    li {
      font-size: 23px;
      font-family: Merriweather-Light;
      margin-bottom: 10px;
      font-weight: normal;
      color: rgb(79, 41, 6) !important;
    }

    li,
    label {
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      -webkit-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -ms-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      &:hover {
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -ms-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        color: rgba(10, 10, 10, 0.9);
      }
    }
    .menu-switch {
      padding-top: 6em;
      label {
        font-size: 3em;
        font-family: "Open Sans Condensed", sans-serif;
      }
    }
    input {
      display: none;
    }
    #menu-collapsed {
      &:checked {
        ~ .menu-content {
          margin-left: -13em;
        }
        ~ .menu-switch .rise {
          display: block;
        }
        ~ .menu-switch .collapse {
          display: none;
        }
      }
      ~ .menu-switch .rise {
        display: none;
      }
    }
  }
`;
const SongListBrollop = styled.div`
  z-index: 100;
  @media screen and (max-width: 1000px) {
    margin-top: -10vh;
    width: 70vw;
    margin-left: 0%;
    display: none;
  }
  display: grid;
  grid-template-columns: 1fr;
  margin-top: -22vh;
  height: 70vh;
  position: absolute;
  margin-left: 5%;
  width: 45vw;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border-radius: px;
  font-style: oblique 2deg;
  font-family: Merriweather-Italic;
  font-weight: 100;
  color: white;
  font-size: 22px;
  background: rgba(188, 126, 29, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(19.5px);
  -webkit-backdrop-filter: blur(19.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  opacity: 0.8;
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(162, 97, 23, 1);
    outline: 1px solid slategrey;
  }

  p {
    margin-left: 6vw;
  }

  button {
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    height: 30px;
    width: 60px;
    position: fixed;
    color: white;
    margin-left: 39.5vw;
    border-radius: 10%;
    margin-top: 0%;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      margin-left: 91vw;
    }
  }
`;
const StyledStart = styled.video`
  position: relative;
  height: 420px;
  width: 44vw;
  object-fit: fill;
  margin-top: 0vh;
  margin-left: 0vw;
`;

const EntireCitatList = styled.div`
  height: 50vh;
  width: 60vw;
  background: rgba(162, 97, 23, 0.59);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(162, 97, 23, 0.98);
  position: absolute;
  z-index: 999;
  left: 24vw;
  top: 10vh;

  .citatText {
    position: relative;
    margin-top: 0vh;
    width: 59.7vw;
    height: 200px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    /* box-shadow: 0 0 12px black; */
    resize: none;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }
  .list {
    overflow: auto;
    max-height: 50vh;
    min-height: 50vh;
  }
  .skicka {
    width: 150px;
    height: 40px;
    font-size: 25px;
    background-color: rgba(162, 97, 23, 1);
    border-radius: 4px;
    border-left: 2px solid black;
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }

  .closeButton {
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    height: 60px;
    width: 60px;
    position: absolute;
    color: white;
    right: 20px;
    top: 32px;
    border-radius: 55%;
    margin-top: -3%;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      margin-left: 91vw;
    }
  }

  table {
    width: 100%;
    overflow: auto;
    max-height: 50vh;
    min-height: 50vh;
  }
  td:first-of-type {
    width: 800px;
    background-color: #ffffff94;
    overflow: auto;
    margin: 0px;
  }

  th {
    font-size: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  tr {
    height: 80px;
  }
  td {
    height: 80px;
    color: black;
  }

  .trTd {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* margin-bottom: 40px; */
    border-bottom: 1px transparent;
    /* height: 30px; */
    width: 90%;
    border-bottom: 1px solid black;
    color: white;
    padding: 10px 15px;
    button {
      background-color: red;
      color: white;
    }
  }

  .trTh {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const EntireLåtList = styled.div`
  height: 50vh;
  width: 60vw;
  background: rgba(162, 97, 23, 0.59);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(162, 97, 23, 0.98);
  position: relative;
  z-index: 999;
  left: 24vw;
  top: -35vh;

  .citatText {
    position: relative;
    margin-top: 0vh;
    width: 59.7vw;
    height: 200px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    resize: none;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }

  form {
    input {
      height: 40px;
      width: 300px;
      margin: 0px;
      border-radius: 5px;
      font-size: 24px;
      box-shadow: 0 0 12px black;
      font-family: "Brush Script MT, Brush Script Std, cursive";
      font-style: italic;
      margin-right: 20px;
    }

    select {
      height: 45px;
      width: 265px;
      margin: 0px;
      border-radius: 5px;
      font-size: 24px;
      box-shadow: 0 0 12px black;
      font-family: "Brush Script MT, Brush Script Std, cursive";
      font-style: italic;
    }
  }

  .list {
    overflow: auto;
    max-height: 50vh;
    min-height: 50vh;
  }
  .skicka {
    width: 150px;
    height: 40px;
    font-size: 25px;
    border-radius: 4px;
    border-left: 2px solid black;
    background-color: rgba(162, 97, 23, 1);
    color: white;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }

  .closeButton {
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    height: 60px;
    width: 60px;
    position: absolute;
    color: white;
    right: 20px;
    top: 32px;
    border-radius: 55%;
    margin-top: -3%;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      margin-left: 91vw;
    }
  }

  table {
    width: 100%;
    overflow: auto;
    max-height: 50vh;
    min-height: 50vh;
  }
  td:first-of-type {
    width: 800px;
    background-color: #ffffff94;
    overflow: auto;
    margin: 0px;
  }

  th {
    font-size: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #ffffff;
  }

  tr {
    height: 40px;
  }
  td {
    height: 40px;
    color: black;
  }

  .trTd {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* margin-bottom: 40px; */
    border-bottom: 1px transparent;
    /* height: 30px; */
    width: 90%;
    border-bottom: 1px solid black;
    color: white;
    padding: 10px 15px;
    button {
      background-color: red;
      color: white;
    }
  }

  .trTh {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
  


