import React from "react";
import styled from "styled-components";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Joi from "joi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contains } from "rsuite/esm/DOMHelper";

function Bokning() {
  const [isMobile, setIsMobile] = useState("");
  const [timer, setTimer] = useState("");
  const [musicType, setmusicType] = useState([]);
  const [showMusic, setshowMusic] = useState(false);
  const [textBrollop, setTextBrollop] = useState(false);
  const [textDop, setTextDop] = useState(false);
  const [musicFilter, setMusicFilter] = useState([]);
  const [textBegravning, setTextBegravning] = useState(false);
  const [musicCategory, setMusicCategory] = useState("Bröllop");
  const [music, setMusic] = useState([]);

  const [formDataError, setFormDataError] = useState({
    förnamn: "",
    efternamn: "",
    epost: "",
    adress: "",
    telefon: "",
    datum: "",
    tidFrån: "",
    tidTill: "",
    text: "",
  });

  function handleReset() {
    return setshowMusic(false);
  }

  const schema = Joi.object({
    förnamn: Joi.string().required().min(2).max(20).messages({
      "string.base": `Förnamn Måste bestå av 'bokstäver'`,
      "string.empty": `Förnamn får ej vara tomt`,
      "string.min": `Förnamn Måste vara minst {#limit} långt`,
      "string.max": `Förnamn Får max vara {#limit} karaktärer långt`,
      "any.required": `Förnamn måste vara ifyllt`,
    }),
    efternamn: Joi.string().min(2).max(20).messages({
      "string.base": `Efternamn Måste bestå av 'bokstäver'`,
      "string.empty": `Efternamn får ej vara tomt`,
      "string.min": `Efternamn Måste vara minst {#limit} långt`,
      "string.max": `Efternamn Får max vara {#limit} karaktärer långt`,
      "any.required": `Efternamn måste vara ifyllt`,
    }),
    epost: Joi.string()
      .email({ tlds: { allow: false } })
      .messages({
        "string.email": `E-post Måste vara en giltig e-post`,
        "string.empty": `E-post får ej vara tomt`,
        "any.required": `E-post måste vara ifyllt`,
      }),
    adress: Joi.string().allow().min(2).max(50),
    telefon: Joi.string()
      .min(9)
      .max(10)
      .pattern(/^[0-9]+$/)
      .required()
      .messages({
        "string.empty": `Telefonnummer får ej vara tomt`,
        "string.min": `Telefonnummer Måste vara minst {#limit} långt`,
        "string.max": `Telefonnummer Får max vara {#limit} siffror långt`,
        "any.required": `Telefonnummer måste vara ifyllt`,
        "string.pattern.base": "Telefonnummer Måste bestå av siffror",
      }),
    datum: Joi.string().required().min(9),
    tidFrån: Joi.allow().messages({
      "string.empty": `Förnamn får ej vara tomt`,
      "any.required": `Förnamn måste vara ifyllt`,
    }),
    tidTill: Joi.allow(),
    text: Joi.string().allow(),
  });

  function validate() {
    const options = { abortEarly: false };
    const { error } = schema.validate(formData, options);
    if (!error) return null;
    const errors = {};
    for (const detail of error.details)
      errors[detail.context.key] = detail.message;

    return errors;
  }

  // function validateProperty({ name, value }) {
  //   const subSchema = this.schema.extract(name);
  //   const { error } = subSchema.validate(value);

  //   if (!error) return null;
  //   return error.message;
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
    const errors = validate();
    formDataError[name] = errors[name];
    setFormDataError({ ...formDataError });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      formDataError["förnamn"] != undefined &&
      formDataError["förnamn"] != ""
    ) {
      toast.warn(formDataError["förnamn"], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (
      formDataError["efternamn"] != undefined &&
      formDataError["efternamn"] != ""
    ) {
      toast.warn(formDataError["efternamn"], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (formDataError["epost"] != undefined && formDataError["epost"] != "") {
      toast.warn(formDataError["epost"], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (formDataError["adress"] != undefined && formDataError["adress"] != "") {
      toast.warn(formDataError["adress"], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (
      formDataError["telefon"] != undefined &&
      formDataError["telefon"] != ""
    ) {
      toast.warn(formDataError["telefon"], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (formDataError["datum"] != undefined && formDataError["datum"] != "") {
      toast.warn(formDataError["datum"], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (
      formDataError["tidFrån"] != undefined &&
      formDataError["tidFrån"] != ""
    ) {
      toast.warn(formDataError["tidFrån"], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (
      formDataError["förnamn"] == undefined &&
      formDataError["efternamn"] == undefined &&
      formDataError["epost"] == undefined &&
      formDataError["adress"] == undefined &&
      formDataError["telefon"] == undefined &&
      formDataError["datum"] == undefined &&
      formDataError["tidFrån"] == undefined
    ) {
      try {
        const order = await axios.post(
          "https://ellinornielsen-backend.onrender.com/api/bokning",
          {
            förnamn: formData.förnamn,
            efternamn: formData.efternamn,
            epost: formData.epost,
            adress: formData.adress,
            telefon: formData.telefon,
            datum: formData.datum,
            tidFrån: formData.tidFrån,
            tidTill: formData.tidTill,
            text: formData.text,
          }
        );
        if (order)
          return (
            alert("Tack för din förfrågan, jag kontakatar er inom kort!"),
            (window.location.href = "/")
          );
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getMusicText();
  }, []);

  async function getMusicText() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/musictext"
    );
    setMusic(data);
  }

  function handleMusicList(e) {
    setMusicCategory(e);
    const data = music.filter((m) => m.category == e);
    return setMusicFilter(data);
  }

  const musicDop = [
    { låt: "Allt har förändrats", artist: "Mattias Gyllengah" },
    { låt: "Det vackraste", artist: "Cecilia Vennersten" },
    { låt: "Du finns inom mig", artist: "Tommy Nilsson" },
    { låt: "En värld full av liv", artist: "Meta Roos" },
    { låt: "Genom eld och vatten", artist: "Sarek" },
    { låt: "Handens fem fingrar", artist: "Lisa Nilsson" },
    { låt: "Himlen måste sakna en ängel", artist: "Linn Maria" },
    { låt: "Just du", artist: "Mariette" },
    { låt: "Kärlekens barn", artist: "Linda Bengtzing" },
    { låt: "Kärleksvisan", artist: "Sarah Dawn Finer" },
    { låt: "Lilla barn", artist: "Sarek" },
    { låt: "Lilla ängel", artist: "Angelita Nooni" },
    { låt: "Lillebror", artist: "Danne Stråhed" },
    { låt: "Till dig", artist: "Sarah Dawn Finer" },
    {
      låt: "Tänk att livet har landat just här",
      artist: "Lina Andersson Burström",
    },
    { låt: "Vad ska du heta?", artist: "Bo Kaspers Orkester" },
    { låt: "Vi är en", artist: "Lejonkungen" },
    { låt: "Välkommen hem", artist: "Sonja Aldén" },
    { låt: "Älskar dig", artist: "Anders Robert" },
    { låt: "Är på väg", artist: "Björnbröder" },
    { låt: "Count on me", artist: "Bruno Mars" },
    { låt: "Grow up to be you", artist: "Måns Zelmerlöw" },
    { låt: "Hand to hold", artist: " JJ Heller" },
    { låt: "How far I´ll go", artist: "Alessia Cara" },
    { låt: "In my daughter's eyes", artist: "Martina McBrid" },
  ];
  const musicBrollop = [
    { låt: "Du är allt", artist: "Sonja Aldén" },
    { låt: "Det vackraste", artist: "Cecilia Vennersten" },
    { låt: "En sång till livet", artist: "Tuva B Larsen, Melena Ernman" },
    { låt: "För kärlekens skull", artist: "Ted Gärdestad" },
    { låt: "I folkviseton", artist: "Nils Ferlin/ Togny Björk" },
    { låt: "Jag ger dig min morgon", artist: "Fred Åkerström" },
    { låt: "Kärleken är evig", artist: "Lena Philipsson" },
    { låt: "Kärleksvisan", artist: "Sarah Dawn Finer" },
    { låt: "Med dig vid min sida", artist: "Sarah Dawn Finer" },
    { låt: "Sofias sång", artist: "Molly Sandén" },
    { låt: "Sång till friheten", artist: "Björn Afzelius" },
    { låt: "Utan dina andetag", artist: "Kent" },
    { låt: "Ännu doftar kärleken", artist: "Marie Fredriksson" },
    { låt: "Amezed", artist: "Lonestar" },
    { låt: "A thousand years", artist: "Christina Perri" },
    { låt: "Can´t help falling in love", artist: "Elvis" },
    { låt: "If ain´t got you", artist: "Alicia Keys" },
    { låt: "I get to love you", artist: "Ruelle " },
    { låt: "I loved her first", artist: "Heartland" },
    { låt: "Isn´t she lovely", artist: "Callum J wright" },
    { låt: "Just the way you are", artist: "Bruno Mars" },
    { låt: "Look at me", artist: "Carrie Underwood" },
    { låt: "Love someone", artist: "Lukas Graham" },
    { låt: "Never be alone", artist: "Shawn Mendes" },
    { låt: "Only you", artist: "Yazoo" },
    { låt: "Perfect", artist: "Ed Sheeran" },
    { låt: "Say yes", artist: "Lukas Graham" },
    { låt: "Stand by me", artist: "Ben E. King" },
    { låt: "Still", artist: "Seinabo Sey" },
    { låt: "The wedding", artist: "Ellen Krauss" },
    { låt: "This will be", artist: "Natahlie Cloe" },
    { låt: "When I found you", artist: "Jasmine Rae" },
    { låt: "When you tell the world you´re mine", artist: "Björn Skifs,Agnes" },
    { låt: "I won´t give up", artist: "Jason Mraz" },
    { låt: "You and I", artist: "León" },
  ];
  const musicBegravning = [
    { låt: "Auld lang syne", artist: "Robert Burns" },
    { låt: "Där som rosor aldrig dör", artist: "Jack Osborn" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
    { låt: "---", artist: "---" },
  ];

  function closeMenu() {
    if (showMusic === true) {
      setshowMusic(false);
    }
  }

  function listSelected(type) {
    setshowMusic(true);
    if (type === "Bröllop") {
      setTextBrollop(true);
      setTextDop(false);
      setTextBegravning(false);
      return setmusicType(musicBrollop);
    }

    if (type === "Dop") {
      setTextDop(true);
      setTextBrollop(false);
      setTextBegravning(false);
      return setmusicType(musicDop);
    }

    if (type === "Begravning") {
      setTextBegravning(true);
      setTextBrollop(false);
      setTextDop(false);
      return setmusicType(musicBegravning);
    }
  }

  const [formData, setFormData] = useState({
    förnamn: "",
    efternamn: "",
    epost: "",
    adress: "",
    telefon: "",
    datum: "",
    tidFrån: "",
    tidTill: "",
    text: "",
  });

  const history = useNavigate();

  const [text, setText] = useState("");
  let element = document.querySelector(".bosse");

  if (element) {
    element.remove();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    handleResize();
    handleTimer();
  }, []);

  function handleTimer() {
    const interval = setInterval(() => {
      return setTimer(true);
    }, 2100);
  }

  function handleResize() {
    if (window.screen.width < 1200) {
      return setIsMobile(true);
    } else {
      return setIsMobile(false);
    }
  }

  const handleText = (e) => {
    setText(e.target.value);
  };

  return (
    <Total>
      {!timer && (
        <Jumping>
          <div className="bosse animate__animated animate__fadeOut">
            <h3 className="text"> Bokning</h3>
          </div>
        </Jumping>
      )}
      <Container>
        <Sidemenu>
          <div className="menu">
            <input type="checkbox" id="menu-collapsed" name="menu-collapsed" />
            <div className="menu-content">
              <h2>Låtförslag</h2>
              <ul>
                <li
                  onClick={() =>
                    listSelected("Begravning") + handleMusicList("Begravning")
                  }
                >
                  Begravning
                </li>
                <li
                  onClick={() => listSelected("Dop") + handleMusicList("Dop")}
                >
                  Dop
                </li>
                <li
                  onClick={() =>
                    listSelected("Bröllop") + handleMusicList("Bröllop")
                  }
                >
                  Bröllop
                </li>
              </ul>
            </div>
            <div className="menu-switch">
              <label className="collapse" htmlFor="menu-collapsed">
                <img src="plectrum.png" height={60} width={60}></img>
              </label>
              <label className="rise" htmlFor="menu-collapsed">
                <img src="plectrum.png" height={60} width={60}></img>
              </label>
            </div>
          </div>
        </Sidemenu>
        {showMusic && (
          <div onClick={() => closeMenu()}>
            {textBrollop && <h1 className="brollopText">Bröllop</h1>}
            {textDop && <h1 className="dopText">Dop</h1>}
            {textBegravning && <h1 className="begravningText">Begravning</h1>}
            <SongListBrollop>
              <button onClick={() => handleReset()}>X</button>
              {musicFilter.map((m, index) => (
                <div key={index}>
                  <p>{`${m?.song} : ${m?.artist}`}</p>
                </div>
              ))}
            </SongListBrollop>
          </div>
        )}
        {!isMobile && (
          <Social>
            <div className="content">
              <Link to="/om" className="link">
                <div className="card">
                  <div className="icon"></div>
                  <p className="title">Om mig</p>
                  <p className="text">Klicka här för att läsa mer om mig.</p>
                </div>
              </Link>

              <Link to="/spelningar" className="link">
                <div className="card">
                  <div className="icon"></div>
                  <p className="title">Min sång</p>
                  <p className="text">
                    Här kan du ta en del av mina tidigare spelningar
                  </p>
                </div>
              </Link>
            </div>
          </Social>
        )}
        {isMobile && (
          <MobileMenu>
            <Social>
              <div className="content">
                <Link to="/om" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Om mig</p>
                    <p className="text">Klicka här för att läsa mer om mig.</p>
                  </div>
                </Link>

                <Link to="/spelningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Min sång</p>
                    <p className="text">
                      Här kan du ta en del av mina tidigare spelningar
                    </p>
                  </div>
                </Link>
              </div>
            </Social>
          </MobileMenu>
        )}

        <StyledForm>
          {!showMusic && (
            <div className="around">
              <div className="first">
                <h1>
                  Är du intresserad av att boka mig för en spelning? Vänligen
                  fyll i formuläret så kontaktar jag dig per mejl eller per
                  telefon.
                </h1>
              </div>

              <form onSubmit={handleSubmit}>
                <StyledInput
                  label={"förnamn"}
                  name={"förnamn"}
                  type={"förnamn"}
                  placeholder={"Förnamn..."}
                  onChange={handleChange}
                  value={formData.förnamn}
                />
                {/* {error && <div className="alert alert-danger">{error}</div>} */}
                <StyledInput
                  label={"efternamn"}
                  name={"efternamn"}
                  type={"efternamn"}
                  placeholder={"Efternamn..."}
                  onChange={handleChange}
                  value={formData.efternamn}
                />

                <StyledInput
                  label={"epost"}
                  name={"epost"}
                  type={"epost"}
                  placeholder={"Epost..."}
                  onChange={handleChange}
                  value={formData.epost}
                />
                <StyledInput
                  label={"telefon"}
                  name={"telefon"}
                  type={"telefon"}
                  placeholder={"Telefon..."}
                  onChange={handleChange}
                  value={formData.telefon}
                />
                <StyledInput
                  label={"adress"}
                  name={"adress"}
                  type={"adress"}
                  placeholder={"Adress..."}
                  onChange={handleChange}
                  value={formData.adress}
                />

                <StyledInput
                  label={"datum"}
                  name={"datum"}
                  type={"text"}
                  placeholder={"Dag..."}
                  onChange={handleChange}
                  value={formData.date}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />

                <StyledInput
                  label={"tidFrån"}
                  name={"tidFrån"}
                  type={"input"}
                  placeholder={"Tid..."}
                  onChange={handleChange}
                  value={formData.tidFrån}
                />
                <StyledSelect
                  name="tidTill"
                  onChange={handleChange}
                  value={formData.tidTill}
                >
                  <option defaultValue="none">Typ av uppdrag...</option>
                  <option value="Bröllop">Bröllop...</option>
                  <option value="Bröllop med mingel">
                    Bröllop med mingel...
                  </option>
                  <option value="Begravning">Begravning...</option>
                  <option value="Dop">Dop...</option>
                  <option value="företagsmingel">Företagsmingel...</option>
                  <option value="fest">Fest...</option>
                  <option value="Annat">Annat...</option>
                </StyledSelect>

                <StyledText
                  className="textwrite"
                  label={"text"}
                  name={"text"}
                  type={"text area"}
                  placeholder={
                    "Här kan ni beskriva mer detaljerat vad bokningen avser, samt eventuella önskemål..."
                  }
                  onChange={handleChange}
                  value={formData.text}
                />
                <button className="skicka" onClick={() => onsubmit}>
                  Skicka
                </button>
              </form>
            </div>
          )}
        </StyledForm>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
      </Container>
    </Total>
  );
}

export default Bokning;

const Total = styled.div`
  .brollopText {
    position: absolute;
    color: black;
    margin-left: 42.5vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.2;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      margin-top: 32%;
      margin-left: 25%;
      font-size: 180px;
      opacity: 0.8;
    }
  }
  .dopText {
    position: absolute;
    color: black;
    margin-left: 47.8vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.2;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      margin-top: 32%;
      margin-left: 35%;
      font-size: 180px;
      opacity: 0.8;
    }
  }
  .begravningText {
    color: black;
    position: absolute;
    margin-left: 34.7vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.2;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      margin-top: 32%;
      margin-left: 13%;
      font-size: 150px;
      opacity: 0.8;
    }
  }
`;
const Jumping = styled.div`
  position: relative;
  display: grid;
  place-content: center;

  .text {
    color: white;
    margin-top: 60vh;
    margin-left: 50%;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    @media screen and (max-width: 1000px) {
      margin-left: 42vw;
      font-size: 100px;
      margin-top: 40vh;
    }
  }
  .bosse {
    background-color: black;
    height: 3000px;
    width: 3000px;
    margin-top: -400px;
    animation-duration: 3s;
    margin-bottom: 400vh;
    @media screen and (max-width: 1000px) {
      height: 200vh;
      width: 120vw;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1;
  margin-right: 1200px;

  .around {
    background-color: tansparent;
    border-radius: 12px;
    padding: 50px;
    box-shadow: 0 0 120px white;
    top: 50px;
  }

  .skicka {
    height: 40px;
    width: 90px;
    border: 2px;
    border-radius: 8px;
    box-shadow: 0 0 12px black;
    background-color: #642828bd;
    color: white;
    margin-left: 25px;
    font-size: 15px;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    @media screen and (max-width: 391px) {
      margin-left: 680px;
    }
    :hover {
      transition: 1s;
      height: 65px;
      width: 115px;
      margin-top: -5px;
      font-size: 24px;
    }
    :not(:hover) {
      transition: 1s;
      width: 110px;
      height: 60px;
      font-size: 24px;
    }
  }
  .first {
    background: rgba(188, 126, 29, 0.45);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(19.5px);
    -webkit-backdrop-filter: blur(19.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    opacity: 0.8;
    border-radius: 32px;
    padding: 20px;
    margin-top: 10%;
    /* max-width: 600px; */
    width: 400px;
    margin-bottom: -20px;
    margin-left: 80%;
    position: absolute;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    color: #3d3535;

    @media screen and (max-width: 1000px) {
      zoom: 70%;
      padding: 20px;
      margin-top: 100%;
      max-width: 600px;
      margin-bottom: -20px;
      margin-left: 48%;
      position: absolute;
      font-family: "Brush Script MT, Brush Script Std, cursive";
      font-style: italic;
      color: #656565;
      font-size: 25px;
    }
  }
  .second {
    padding: 20px;
    margin-left: 150px;
    margin-top: -200px;
    margin-bottom: -20px;

    position: absolute;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    color: #656565;
    @media screen and (max-width: 491px) {
      width: 600px;
    }
  }
`;

const StyledInput = styled.input`
  height: 40px;
  width: 300px;
  margin: 20px;
  border-radius: 5px;
  font-size: 24px;
  box-shadow: 0 0 12px black;
  font-family: "Brush Script MT, Brush Script Std, cursive";
  font-style: italic;
`;
const StyledSelect = styled.select`
  height: 40px;
  width: 300px;
  margin: 20px;
  border-radius: 5px;
  font-size: 24px;
  box-shadow: 0 0 12px black;
  font-family: "Brush Script MT, Brush Script Std, cursive";
  font-style: italic;
`;
const StyledText = styled.textarea`
  margin-left: 20px;
  margin-right: 20px;
  width: 650px;
  height: 300px;
  border-radius: 5px;
  box-shadow: 0 0 12px black;
  resize: none;
  font-family: "Brush Script MT, Brush Script Std, cursive";
  font-style: italic;
`;

const Social = styled.div`
  @media screen and (max-width: 1000px) {
    zoom: 150%;
  }
  z-index: 999;
  .link {
    text-decoration: none;
    position: relative;
    max-width: 200px;
    margin-left: 20px;
  }
  text-decoration: none;
  body {
    width: 100vw;
    background-color: #1d1d1d;
    margin: 0;
    font-family: helvetica;
  }

  .about {
    $cubic: cubic-bezier(0.64, 0.01, 0.07, 1.65);
    $transition: 0.6s $cubic;
    $size: 40px;
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    width: $size;
    height: $size;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;

    .bg_links {
      width: $size;
      height: $size;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#fff, 0.2);
      border-radius: 100%;
      backdrop-filter: blur(5px);
      position: absolute;
    }

    .logo {
      width: $size;
      height: $size;
      z-index: 9;
      background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 10px 7px;
      opacity: 0.9;
      transition: all 1s 0.2s ease;
      bottom: 0;
      right: 0;
    }

    .social {
      opacity: 0;
      right: 0;
      bottom: 0;

      .icon {
        width: 100%;
        height: 100%;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        display: flex;
        transition: all 0.2s ease, background-color 0.4s ease;
        opacity: 0;
        border-radius: 100%;
      }

      &.portfolio {
        transition: all 0.8s ease;

        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
        }
      }

      &.dribbble {
        transition: all 0.3s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
        }
      }

      &.linkedin {
        transition: all 0.8s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
        }
      }
    }

    &:hover {
      width: 105px;
      transition: all $transition;

      .logo {
        opacity: 1;
        transition: all 0.6s ease;
      }

      .social {
        opacity: 1;

        .icon {
          opacity: 0.9;
        }

        &:hover {
          background-size: 28px;
          .icon {
            background-size: 65%;
            opacity: 1;
          }
        }

        &.portfolio {
          right: 0;
          bottom: calc(100% - 40px);
          transition: all 0.3s 0s $cubic;

          .icon {
            &:hover {
              background-color: #698fb7;
            }
          }
        }

        &.dribbble {
          bottom: 45%;
          right: 45%;
          transition: all 0.3s 0.15s $cubic;
          .icon {
            &:hover {
              background-color: #ea4c89;
            }
          }
        }

        &.linkedin {
          bottom: 0;
          right: calc(100% - 40px);
          transition: all 0.3s 0.25s $cubic;
          .icon {
            &:hover {
              background-color: #0077b5;
            }
          }
        }
      }
    }
  }

  .wrapper {
    width: 100vw;
    margin: 0 auto;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      height: 700px;
    }
  }

  .content {
    max-width: 1024px;
    width: 30%;
    height: 30%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 28%;
    bottom: -5%;
    position: fixed;
    @media screen and (max-width: 1000px) {
      left: 28%;
    }
  }

  @media screen and (max-width: 767px) {
    .content {
      /* padding-top: 300px;
      flex-direction: column; */
    }
  }

  .card {
    width: 50%;
    max-width: 300px;
    min-width: 200px;
    height: 150px;
    background-color: rgba(193, 150, 86, 0.781);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 12px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mic {
      margin-left: -200px;
      margin-top: -90px;
      height: 80px;
      width: 80px;
      position: relative;
    }

    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
      margin-top: -120px;
    }
  }

  .icon {
  }

  .material-icons.md-18 {
    font-size: 12px;
  }
  .material-icons.md-24 {
    font-size: 12px;
  }
  .material-icons.md-36 {
    font-size: 28px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: -40px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 20px;
  }

  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
  }

  .card:hover {
    height: 270px;
  }

  .card:hover .info {
    height: 90%;
  }
  .card:hover .info {
    height: 90%;
  }

  .card:hover .text {
    opacity: 1;
    max-height: 40px;
    margin-top: 0px;
    font-size: 15px;
  }

  .card:hover .icon {
    background-position: -120px;
    margin-top: -40px;
    height: 150px;
  }

  .card:hover .title {
    margin-top: -200px;
    margin-bottom: 60px;
  }

  .card:hover .icon i {
    background: linear-gradient(90deg, #ff7e7e, #ff4848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
  }
`;

const StyledForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -42vh;
  zoom: 90%;
  position: absolute;
  max-width: 1000px;
  min-width: 900px;
  margin-left: 28%;

  .datePicker {
    height: 40px;
    width: 300px;
    margin: 20px;
    border-radius: 5px;
    font-size: 24px;
    box-shadow: 0 0 12px black;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
  }

  .textwrite {
    font-size: 20px;
  }

  @media screen and (max-width: 1000px) {
    position: absolute;
    margin-left: 20%;
    margin-top: -17%;
    width: 350px;
  }
`;

const MobileMenu = styled.div`
  body {
    margin: 0;
    padding: 0;

    /* make it look decent enough */
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle {
    display: block;
    position: fixed;
    top: 350px;
    left: 50px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 120px;
    height: 102px;
    position: absolute;
    top: -7px;
    left: -22px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 82px;
    height: 12px;
    margin-bottom: 15px;
    position: relative;

    background: #737373;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(1px, 1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 180vw;
    height: 200vh;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 52px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }
`;

const Sidemenu = styled.div`
  @media screen and (max-width: 1000px) {
    zoom: 150%;
    margin-top: 50%;
    margin-left: -5%;
  }
  position: absolute;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  font-family: Merriweather-Light;
  color: rgb(79, 41, 6);

  .menu {
    .menu-content {
      -webkit-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -ms-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      float: left;
      width: 12em;
      height: 50%;
      width: 150px;
      margin-top: 3em;

      h2 {
        font-family: Merriweather-Light;
        font-weight: normal;
        margin-left: 1em;
        color: black;
        margin-left: 38px;
        border-bottom: 1px solid black;
      }
      ul {
        list-style: none;
        max-height: 200px;
      }
      ul li:hover {
        font-size: 25px;
      }
    }

    li {
      font-size: 23px;
      font-family: Merriweather-Light;
      margin-bottom: 10px;
      font-weight: normal;
      color: rgb(79, 41, 6) !important;
    }

    li,
    label {
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      -webkit-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -ms-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      &:hover {
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -ms-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        color: rgba(10, 10, 10, 0.9);
      }
    }
    .menu-switch {
      padding-top: 6em;
      label {
        font-size: 3em;
        font-family: "Open Sans Condensed", sans-serif;
      }
    }
    img {
      margin-left: 30px;
      margin-top: 31px;
    }
    input {
      display: none;
    }
    #menu-collapsed {
      &:checked {
        ~ .menu-content {
          margin-left: -13em;
          @media screen and (max-width: 1000px) {
            margin-left: -9em;
          }
        }
        ~ .menu-switch .rise {
          display: block;
        }
        ~ .menu-switch .collapse {
          display: none;
        }
      }
      ~ .menu-switch .rise {
        display: none;
      }
    }
  }
`;

const SongListBrollop = styled.div`
  @media screen and (max-width: 1000px) {
    margin-top: -10vh;
    width: 100vw;
    margin-left: 5%;
    grid-template-columns: 1fr 1fr;
    opacity: 0.9;
  }
  display: grid;
  grid-template-columns: 1fr;
  margin-top: -22vh;
  height: 70vh;
  position: absolute;
  margin-left: 30%;
  width: 45vw;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  font-style: oblique 2deg;
  font-family: Merriweather-Italic;
  font-weight: 100;
  color: white;
  font-size: 22px;
  background: rgba(162, 97, 23, 0.59);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(162, 97, 23, 0.98);
  opacity: 0.8;

  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(162, 97, 23);
    outline: 1px solid slategrey;
  }

  p {
    margin-left: 6vw;
  }

  button {
    background-color: rgba(162, 97, 23, 1);
    border: 0px;
    height: 30px;
    width: 60px;
    position: fixed;
    color: white;
    margin-left: 39.5vw;
    border-radius: 10%;
    margin-top: 0%;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      margin-left: 91vw;
    }
  }
`;

