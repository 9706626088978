import jwtDecode from "jwt-decode";
import http from "./http";
import axios from "axios";
const apiEndpoint = "login";
const tokenKey = "token";

http.setAuthHeader(getJwt());

export async function login(user) {
  const { data: jwt } = await axios.post(
    "https://ellinornielsen-backend.onrender.com/api/login",
    {
      email: user.email,
      password: user.password,
    }
  );
  localStorage.setItem(tokenKey, jwt);
}

function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

function logout() {
  localStorage.removeItem(tokenKey);
}

async function getCurrentUser() {
  try {
    const userToken = localStorage.getItem(tokenKey) || "";
    const user = await jwtDecode(userToken);
    return user;
  } catch (error) {
    return null;
  }
}

function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  logout,
  loginWithJwt,
  getCurrentUser,
  getJwt,
};
