import React from "react";
import { Map, Marker } from "pigeon-maps";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";

function Maps({ testY, testX }) {
  const [center, setCenter] = useState(["", ""]);
  const [zoom, setZoom] = useState(11);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {}, []);

  useEffect(() => {
    setLatitude(testX);
    setLongitude(testY);
    setCenter([testX, testY]);
  }, [testX]);

  return (
    <StyledMaps>
      <Map
        height={700}
        width={700}
        center={center}
        zoom={zoom}
        onBoundsChanged={({ center, zoom }) => {
          setCenter(center);
          setZoom(zoom);
        }}
      >
        <Marker width={50} anchor={[latitude, longitude]} />
        <Marker width={50} anchor={[latitude, longitude]}></Marker>
      </Map>
    </StyledMaps>
  );
}

export default Maps;

const StyledMaps = styled.div`
  height: 300px;
  width: 300px;
  margin: 5px;
`;
