import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import auth from "../services/auth";
import { useNavigate, Link } from "react-router-dom";
import Maps from "./Maps";
import AnimatedPage from "../AnimatedPage";
import "animate.css/animate.min.css";
import _, { random, repeat } from "lodash";
import Lenis from "@studio-freight/lenis";
import Ukiyo from "ukiyojs";

function OmPage({ handleUser }) {
  const [formData, setFormData] = useState({
    latitude: "",
    longitude: "",
  });

  const [user, setUser] = useState();
  const [view, setView] = useState(null);
  const [showCitat, setShowCitat] = useState("Fina recentioner efter uppdrag");
  const [isMobile, setIsMobile] = useState("");
  const [timer, setTimer] = useState("");
  const [musicType, setmusicType] = useState([]);
  const [showMusic, setshowMusic] = useState(false);
  const [textBrollop, setTextBrollop] = useState(false);
  const [textDop, setTextDop] = useState(false);
  const [textBegravning, setTextBegravning] = useState(false);

  let history = useNavigate();

  const musicDop = [
    { låt: "dop1", artist: "artist1" },
    { låt: "dop2", artist: "artist2" },
    { låt: "dop3", artist: "artist3" },
    { låt: "dop4", artist: "artist4" },
    { låt: "dop5", artist: "artist5" },
    { låt: "dop6", artist: "artist6" },
    { låt: "dop7", artist: "artist7" },
    { låt: "dop8", artist: "artist8" },
    { låt: "dop9", artist: "artist9" },
    { låt: "dop10", artist: "artist10" },
    { låt: "dop11", artist: "artist11" },
    { låt: "dop12", artist: "artist12" },
    { låt: "dop13", artist: "artist13" },
    { låt: "dop14", artist: "artist14" },
    { låt: "dop15", artist: "artist15" },
    { låt: "dop16", artist: "artist16" },
  ];
  const musicBrollop = [
    { låt: "bröllop1", artist: "artist1" },
    { låt: "bröllop2", artist: "artist2" },
    { låt: "bröllop3", artist: "artist3" },
    { låt: "bröllop4", artist: "artist4" },
    { låt: "bröllop5", artist: "artist5" },
    { låt: "bröllop6", artist: "artist6" },
    { låt: "bröllop7", artist: "artist7" },
    { låt: "bröllop8", artist: "artist8" },
    { låt: "bröllop9", artist: "artist9" },
    { låt: "bröllop10", artist: "artist10" },
    { låt: "bröllop11", artist: "artist11" },
    { låt: "bröllop12", artist: "artist12" },
    { låt: "bröllop13", artist: "artist13" },
    { låt: "bröllop14", artist: "artist14" },
    { låt: "bröllop15", artist: "artist15" },
    { låt: "bröllop16", artist: "artist16" },
  ];
  const musicBegravning = [
    { låt: "begravning1", artist: "artist1" },
    { låt: "begravning2", artist: "artist2" },
    { låt: "begravning3", artist: "artist3" },
    { låt: "begravning4", artist: "artist4" },
    { låt: "begravning5", artist: "artist5" },
    { låt: "begravning6", artist: "artist6" },
    { låt: "begravning7", artist: "artist7" },
    { låt: "begravning8", artist: "artist8" },
    { låt: "begravning9", artist: "artist9" },
    { låt: "begravning10", artist: "artist10" },
    { låt: "begravning11", artist: "artist11" },
    { låt: "begravning12", artist: "artist12" },
    { låt: "begravning13", artist: "artist13" },
    { låt: "begravning14", artist: "artist14" },
    { låt: "begravning15", artist: "artist15" },
    { låt: "begravning16", artist: "artist16" },
  ];

  function closeMenu() {
    if (showMusic === true) {
      setshowMusic(false);
    }
  }

  function listSelected(type) {
    setshowMusic(true);
    if (type === "Bröllop") {
      setTextBrollop(true);
      setTextDop(false);
      setTextBegravning(false);
      return setmusicType(musicBrollop);
    }

    if (type === "Dop") {
      setTextDop(true);
      setTextBrollop(false);
      setTextBegravning(false);
      return setmusicType(musicDop);
    }

    if (type === "Begravning") {
      setTextBegravning(true);
      setTextBrollop(false);
      setTextDop(false);
      return setmusicType(musicBegravning);
    }
  }

  let element = document.querySelector(".bosse");

  if (element) {
    element.remove();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
    handleResize();
    handleTimer();

    const els = document.querySelectorAll(".ukiyo");
    els.forEach((el) => {
      const parallax = new Ukiyo(el, {
        scale: 1.14, // 1~2 is recommended
        speed: 2, // 1~2 is recommended
        //extrema är 15
        willChange: false, // This may not be valid in all cases
        wrapperClass: "ukiyo-wrapper",
        externalRAF: false,
      });
    });

    // smooth scroll
    const lenis = new Lenis({
      lerp: 0.1,
      smoothWheel: false,
      direction: "vertical",
    });

    function raf() {
      lenis.raf();
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  const citat = [
    "Du sjunger väldigt vackert o vilktet fint program ni satte ihop om Ted Gärdestad",
    "Tack för ett jättefint framträdande, många som berömde dig.. Du har en stor talang och det skulle vara roligt att höra dig fler gånger",
    "Tack så mycket för en otroligt vacker sång",
    "Stort tack för din fina sång, den skapade stämning och gjorde dopet personligt.Seriöst att du tog dig tid att träffa musikern innan också.Det kändes som du hade bra koll på läget.",
  ];

  function handleResize() {
    if (window.screen.width < 1200) {
      return setIsMobile(true);
    } else {
      return setIsMobile(false);
    }
  }

  function handleTimer() {
    const interval = setInterval(() => {
      return setTimer(true);
    }, 2100);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const random = citat[_.random(0, citat.length - 1)];
      setShowCitat(random);
    }, 15100);
    return () => clearInterval(interval);
  }, []);

  async function getUser() {
    const user = await auth.getCurrentUser();
    if (user) setUser(user);
    return handleUser(user);
  }

  function handleReset() {
    return setView(null);
  }

  const onLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    handleUser(user);
    return window.location.href;
  };

  const onChange = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    formData[name] = value;
    setFormData({ ...formData });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const map = await axios.post(
        "https://ellinornielsen-backend.onrender.com/api/maps",
        {
          latitude: formData.latitude,
          longitude: formData.longitude,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Total>
      {!timer && (
        <JumpingSecond>
          <div className="bosse animate__animated animate__fadeOut">
            <h3 className="text"> Ellinor Nielsen</h3>
          </div>
        </JumpingSecond>
      )}
      <div>
        {user && <Logout onClick={onLogout}>Logout</Logout>}

        {user && <Container>{!view && <StyledDiv></StyledDiv>}</Container>}
        <Container2>
          <div className="arrow">
            <span></span>
            <span></span>
            <span></span>
            <div className="arrow-text"></div>
          </div>
          <div className="arrowsecond">
            <span></span>
            <span></span>
            <span></span>
            <div className="arrow-text-second"></div>
          </div>

          <Parallax>
            <video
              className="startVideo"
              autoPlay={true}
              muted={false}
              loop={true}
              src={"omvideo-compressed.mp4"}
            ></video>
            <div id="container">
              <section className="fv"></section>

              <section className="content">
                <img className="first-picture" src="ellinor1.jpg" />
                <h1 className="first-text">
                  Ellinor Nielsen, 27 år gammal.<br></br> Jag är född i Malmö
                  men uppvuxen i Skivarp, en by utan för Skurup.<br></br> Jag
                  kom tillbaka till landet och till Skurup för ett par år sedan
                  och trivs bra här.<br></br> Jag började sjunga tidigt framför
                  spegelgarderoberna på min storasysters rum hemma i Skivarp.
                  <br></br>
                  <br></br>
                  Jag filmade mig själv när jag sjöng och framför spegeln och
                  lekte ofta ”Sikta på mot stjärnorna”. <br></br> Min stora som
                  barn att stå på scen och att sjunga. Jag ville bli känd,
                  kanske för det var en stor kontrast till det livet jag levde
                  och det lockade. Som barn drömde jag även att bli vuxen
                  snabbt, för att kunna driva mina drömmar snabbt och jag hade
                  många idéer.
                </h1>
                <picture>
                  <img className="ukiyo img-size-full" src="ellinor2.jpg" />
                </picture>
                <div></div>
                <div className="divtitle1">
                  <h1 className="title1">
                    Är det inte jobbigt att sjunga på begravningar?
                  </h1>
                </div>

                <h1 className="second-text">
                  Det är en fråga jag har fått många gånger. Ja, det kan vara
                  jobbigt.
                  <br></br>
                  Det är den avlides sista stund och en viktig cermoni för
                  anhöriga, släkt och vänner som ska ta ett sista farväl.
                  <br></br> Även om det inte är en person jag känner påverkar
                  det mig att vara där och bevittna denna sorg.<br></br>
                  Samtidigt har jag ett uppdrag, jag ska sjunga och förmedla
                  känslor via musiken till den avlidne och till de som närvarar,
                  för att göra ett fint avslut som möjligt.<br></br>
                  Det är ett hedersuppdrag och jag har utvecklat en styrka att
                  klara av det.<br></br>Jag har pratat en hel del med olika
                  musiker genom åren och det är många jag har mött som inte
                  klarar av att sjunga i en så svår stund.<br></br>
                  Jag är tacksam att jag klarar av det och att jag är en av de
                  sångare som kan göra det i denna svåra stund. Det är klart att
                  det påverkar en.<br></br>Det vore konstigt annars. Men jag har
                  lärt mig att använda det känslomässiga till en styrka.
                  <br></br>Jag använder det när jag sjunger.<br></br>
                  När jag var 18 år gammal knackade jag på en begravningsbyrå,
                  lämnade en inspelning och berättade om mig själv. "Jag har
                  aldrig sjungit på en begravning, men jag skulle vilja prova"
                  sa jag till begravningsentreprenören och så blev det.
                  <br></br>Begravningsbryån bokade mig och än idag har vi ett
                  fint samarbete, då jag sjungit på flertalet begravningar sedan
                  dess.
                </h1>
                <img className="ukiyo img-size-full" src="ellinor5.jpg" />
                <img className="thirdPicture" src="ellinor8.jpg" />
                <h1 className="third-text">
                  En fråga jag ofta får är om låten anhöriga föreslår exmepelvis
                  kan passa på en begravning.<br></br>Det finns många fina låtar
                  som kan passa på en begravning.<br></br>Jag brukar tänka,
                  känns det rätt är det rätt.<br></br>Det viktiga är att det
                  känns rätt för de närmst sörjande.
                  <br></br>Mitt i sorgen, när dödsfallet precis har inträffat är
                  det mycket praktiskt som ska göras och en begravning ska
                  planeras.<br></br>Jag erbjuder därför alltid ett möte eller
                  samtal med de som bokar mig, om man har svårt att välja musik.
                  <br></br>Under samtalet kan jag komma med låtförslag och ni
                  får möjlighet att fundera vidare.<br></br>Var inte rädd för
                  att fråga.<br></br>Jag kan även besvara praktiska frågor, hur
                  det går till och i vilken ordning.
                </h1>
                <img
                  className="ukiyo img-position-l img-size-2"
                  src="ellinor9.jpg"
                />
                {!isMobile && (
                  <video
                    className="img-size-full"
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    src={"ellinor6.mp4"}
                    style={{
                      height: 1000,
                      // width: "30%",
                      marginLeft: 0 + "%",
                      marginTop: 10 + "%",
                      marginBottom: 4 + "%",
                      aspectRatio: "auto",
                    }}
                  ></video>
                )}

                {isMobile && (
                  <video
                    className="img-size-full"
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    src={"ellinor6.mp4"}
                    style={{
                      height: 3000,
                      width: 3000,
                      marginLeft: 0 + "%",
                      marginTop: 10 + "%",
                    }}
                  ></video>
                )}
              </section>
            </div>
          </Parallax>

          {!isMobile && !user && (
            <Social>
              <div className="content">
                <Link to="/spelningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Min sång</p>
                    <p className="text">
                      Här kan du ta en del av mina tidigare spelningar
                    </p>
                  </div>
                </Link>

                <Link to="/bokning" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Bokning</p>
                    <p className="text">
                      Ta kontakt med mig här, bokning innebär en kommunikation
                      där jag kontaktar er för bäst möjliga förståelse och
                      framträdande
                    </p>
                  </div>
                </Link>
              </div>
            </Social>
          )}
          {!isMobile && user && (
            <Social>
              <div className="content">
                <Link to="/spelningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Min sång</p>
                    <p className="text">
                      Här kan du ta en del av mina tidigare spelningar
                    </p>
                  </div>
                </Link>

                <Link to="/bokningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Bokningar</p>
                    <p className="text">
                      Ta kontakt med mig här, bokning innebär en kommunikation
                      där jag kontaktar er för bäst möjliga förståelse och
                      framträdande
                    </p>
                  </div>
                </Link>
              </div>
            </Social>
          )}
          {isMobile && !user && (
            <Social>
              <div className="content">
                <Link to="/spelningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Min sång</p>
                    <p className="text">
                      Här kan du ta en del av mina tidigare spelningar
                    </p>
                  </div>
                </Link>

                <Link to="/bokning" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Bokning</p>
                    <p className="text">
                      Ta kontakt med mig här, bokning innebär en kommunikation
                      där jag kontaktar er för bäst möjliga förståelse och
                      framträdande
                    </p>
                  </div>
                </Link>
              </div>
            </Social>
          )}

          {isMobile && user && (
            <Social>
              <div className="content">
                <Link to="/spelningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Min sång</p>
                    <p className="text">
                      Här kan du ta en del av mina tidigare spelningar
                    </p>
                  </div>
                </Link>

                <Link to="/bokningar" className="link">
                  <div className="card">
                    <div className="icon"></div>
                    <p className="title">Bokningar</p>
                    <p className="text">
                      Ta kontakt med mig här, bokning innebär en kommunikation
                      där jag kontaktar er för bäst möjliga förståelse och
                      framträdande
                    </p>
                  </div>
                </Link>
              </div>
            </Social>
          )}
        </Container2>
      </div>
      {/* <Jumping>
        <div className="bosse animate__animated animate__bounceInDown animate__infinite">
          <h3> {showCitat}</h3>
        </div>
      </Jumping> */}
    </Total>
  );
}
export default OmPage;

const Total = styled.div`
  @media screen and (max-width: 1000px) {
    overflow-x: hidden;
  }

  .brollopText {
    z-index: 99;
    position: absolute;
    color: black;
    margin-left: 36.5vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.6;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .dopText {
    z-index: 99;
    position: absolute;
    color: black;
    margin-left: 40.5vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.6;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .begravningText {
    z-index: 99;
    color: black;
    position: absolute;
    margin-left: 31.8vw;
    font-size: 120px;
    margin-top: 0vh;
    opacity: 0.6;
    font-style: oblique 2deg;
    font-family: Merriweather-Italic;
    font-weight: 100;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
`;
const JumpingSecond = styled.div`
  position: relative;
  display: grid;
  place-content: center;

  .text {
    color: white;
    margin-top: 60vh;
    margin-left: 50%;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    @media screen and (max-width: 1000px) {
      margin-left: 52%;
      font-size: 100px;
      margin-top: 60vh;
    }
  }
  .bosse {
    background-color: black;
    height: 3000px;
    width: 3000px;
    margin-top: -400px;
    animation-duration: 3s;
    @media screen and (max-width: 1000px) {
      height: 200vh;
      width: 320vw;
    }
  }
`;
const Container = styled.div`
  /* height: 200px;
  display: grid;
  margin-right: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative; */
`;
const Container2 = styled.div`
  height: 300vh;
  display: grid;
  max-width: 10%;

  .arrow {
    z-index: 999;
    position: absolute;
    top: 10%;
    left: 100vw;
    transform: translate(-50%, -50%);
    transform: rotate(0deg);
    cursor: pointer;

    .arrow-text {
      width: 200px;
      transform: rotate(0deg);
      font-size: 30px;
      margin-left: -4vw;
      margin-top: 5vh;
      @media screen and (max-width: 1000px) {
        margin-left: -7vw;
      }
    }
  }

  .arrow span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 10px solid white;
    border-right: 10px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
  }

  .arrow span:nth-child(2) {
    animation-delay: -0.2s;
  }

  .arrow span:nth-child(3) {
    animation-delay: -0.4s;
  }
  .arrowsecond {
    z-index: 999;
    position: absolute;
    top: 10%;
    left: 10vw;
    transform: translate(-50%, -50%);
    transform: rotate(0deg);
    cursor: pointer;

    @media screen and (max-width: 1000px) {
      left: 13vw;
    }

    .arrow-text-second {
      width: 250px;
      transform: rotate(0deg);
      font-size: 30px;
      margin-left: -4vw;
      margin-top: 5vh;

      @media screen and (max-width: 1000px) {
        margin-left: -7vw;
      }
    }
  }

  .arrowsecond span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 10px solid white;
    border-right: 10px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
  }

  .arrowsecond span:nth-child(2) {
    animation-delay: -0.2s;
  }

  .arrowsecond span:nth-child(3) {
    animation-delay: -0.4s;
  }

  @keyframes animate {
    0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
    }
  }

  position: relative;
  @media screen and (max-width: 1000px) {
    max-width: 10%;
  }

  img {
    margin: 30px;
  }
  video {
    /* margin: 30px; */
  }
`;

const Logout = styled.button`
  z-index: 999;
  width: 150px;
  height: 40px;
  font-size: 25px;
  border-radius: 4px;
  border-left: 2px solid black;
  background-color: rgba(162, 97, 23, 1);
  color: white;
  font-family: "Brush Script MT, Brush Script Std, cursive";
  font-style: italic;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Social = styled.div`
  z-index: 99;
  @media screen and (max-width: 1000px) {
    zoom: 150%;
  }

  .link {
    text-decoration: none;
    position: relative;
    max-width: 200px;
    margin-left: 20px;
  }
  text-decoration: none;
  body {
    width: 100vw;
    background-color: #1d1d1d;
    margin: 0;
    font-family: helvetica;
  }

  .about {
    $cubic: cubic-bezier(0.64, 0.01, 0.07, 1.65);
    $transition: 0.6s $cubic;
    $size: 40px;
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    width: $size;
    height: $size;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;

    .bg_links {
      width: $size;
      height: $size;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#fff, 0.2);
      border-radius: 100%;
      backdrop-filter: blur(5px);
      position: absolute;
    }

    .logo {
      width: $size;
      height: $size;
      z-index: 9;
      background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 10px 7px;
      opacity: 0.9;
      transition: all 1s 0.2s ease;
      bottom: 0;
      right: 0;
    }

    .social {
      opacity: 0;
      right: 0;
      bottom: 0;

      .icon {
        width: 100%;
        height: 100%;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        display: flex;
        transition: all 0.2s ease, background-color 0.4s ease;
        opacity: 0;
        border-radius: 100%;
      }

      &.portfolio {
        transition: all 0.8s ease;

        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
        }
      }

      &.dribbble {
        transition: all 0.3s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
        }
      }

      &.linkedin {
        transition: all 0.8s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
        }
      }
    }

    &:hover {
      width: 105px;
      height: 105px;
      transition: all $transition;

      .logo {
        opacity: 1;
        transition: all 0.6s ease;
      }

      .social {
        opacity: 1;

        .icon {
          opacity: 0.9;
        }

        &:hover {
          background-size: 28px;
          .icon {
            background-size: 65%;
            opacity: 1;
          }
        }

        &.portfolio {
          right: 0;
          bottom: calc(100% - 40px);
          transition: all 0.3s 0s $cubic;

          .icon {
            &:hover {
              background-color: #698fb7;
            }
          }
        }

        &.dribbble {
          bottom: 45%;
          right: 45%;
          transition: all 0.3s 0.15s $cubic;
          .icon {
            &:hover {
              background-color: #ea4c89;
            }
          }
        }

        &.linkedin {
          bottom: 0;
          right: calc(100% - 40px);
          transition: all 0.3s 0.25s $cubic;
          .icon {
            &:hover {
              background-color: #0077b5;
            }
          }
        }
      }
    }
  }

  .wrapper {
    width: 100vw;
    margin: 0 auto;
    height: 400px;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      height: 700px;
    }
  }

  .content {
    max-width: 1024px;
    width: 30%;
    height: 30%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 28%;
    bottom: -5%;
    position: fixed;
    @media screen and (max-width: 1000px) {
      left: 28%;
    }
  }

  @media screen and (max-width: 767px) {
    .content {
      /* padding-top: 300px;
      flex-direction: column; */
    }
  }

  .card {
    width: 50%;
    max-width: 300px;
    min-width: 200px;
    height: 150px;
    background-color: rgba(193, 150, 86, 0.781);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 12px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mic {
      margin-left: -200px;
      margin-top: -90px;
      height: 80px;
      width: 80px;
      position: relative;
    }

    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
      margin-top: -120px;
    }
  }

  .icon {
  }

  .material-icons.md-18 {
    font-size: 12px;
  }
  .material-icons.md-24 {
    font-size: 12px;
  }
  .material-icons.md-36 {
    font-size: 28px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: -40px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 20px;
  }

  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
  }

  .card:hover {
    height: 270px;
  }

  .card:hover .info {
    height: 90%;
  }
  .card:hover .info {
    height: 90%;
  }

  .card:hover .text {
    opacity: 1;
    max-height: 40px;
    margin-top: 0px;
    font-size: 15px;
  }

  .card:hover .icon {
    background-position: -120px;
    margin-top: -40px;
    height: 150px;
  }

  .card:hover .title {
    margin-top: -200px;
    margin-bottom: 60px;
  }

  .card:hover .icon i {
    background: linear-gradient(90deg, #ff7e7e, #ff4848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
  }
`;

const Selected = styled.div`
  height: 900px;
  width: 900px;
  margin-left: 200px;
`;

const StyledMapsButton = styled.div`
  position: absolute;
  margin-left: 653px;
  margin-top: -305px;
  height: 40px;
  width: 53px;
  button {
    height: 40px;
    width: 53px;
    background-color: red;
    border: 1px solid gray;
  }
`;
const StyledMap = styled(Maps)`
  position: absolute;
  height: 30px;
  width: 30px;
`;
const StyledDirection = styled.div`
  height: 150px;
  width: 200px;
  display: flex;
  float: right;
  right: 10px;
  margin-top: -100px;
  position: absolute;
  @media screen and (max-width: 391px) {
    margin-left: 1100px;
  }
  h3 {
    float: right;
    margin-right: 100px;
    color: black;
  }
`;
const StyledStart = styled.video`
  height: 1000px;
  width: 1000px;
  position: relative;
  margin-bottom: 200px;
  @media screen and (max-width: 491px) {
    height: 500px;
    width: 500px;
    top: 100px;
  }
`;

const Jumping = styled.div`
  bottom: 80px;
  position: fixed;
  right: 30px;
  display: grid;
  place-content: center;
  z-index: 99;
  @media screen and (max-width: 391px) {
    margin-left: 980px;
  }

  h3 {
    display: grid;
    place-content: center;
    border-radius: 32px;
    height: 120%;
    max-height: 120px;
    width: 15em;
    background-color: white;
    opacity: 0.5;
    color: black;
    padding: 20px;
  }
  div {
    animation-duration: 15s;
  }
`;

const StyledDiv = styled.div`
  margin-top: -200px;
  margin-left: -200px;

  @media screen and (max-width: 491px) {
    margin-left: -480px;
  }
`;
const StyledDiv2 = styled.div`
  margin-top: -200px;
  margin-left: -200px;

  @media screen and (max-width: 491px) {
    margin-left: -820px;
  }
`;
const StyledInner = styled.div`
  position: absolute;
  background-color: black;
  height: 710px;
  width: 710px;
`;

const MobileMenu = styled.div`
  body {
    margin: 0;
    padding: 0;

    /* make it look decent enough */
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle {
    display: block;
    position: fixed;
    top: 350px;
    left: 50px;
    margin-right: 10vw;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 120px;
    height: 102px;
    position: absolute;
    top: -7px;
    left: -22px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 82px;
    height: 12px;
    margin-bottom: 15px;
    position: relative;

    background: #737373;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(1px, 1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 180vw;
    height: 200vh;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 52px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .mic {
    height: 200px;
    margin-left: -20px;
    position: fixed;
    margin-top: 10px;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      height: 300px;
    }
  }
`;
const Parallax = styled.div`
  zoom: 80%;
  margin-left: 14%;
  @media screen and (max-width: 1000px) {
    zoom: 40%;
    max-width: 70%;
    margin-top: -120vh;
  }

  .startVideo {
    position: fixed;
    object-fit: cover;
    height: 140vh;
    width: 138vw;
    top: 0px;
    left: 0px;
    filter: blur(10px);

    @media screen and (max-width: 1000px) {
      height: 110%;
      width: 100%;
      margin-top: 50vh;
    }
  }

  margin-top: -40vh;
  img {
    position: relative;
  }
  width: 200vh;
  section {
    position: relative;
    width: 100%;
  }

  body {
    color: #fff;
    background-color: #2b2b2b;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    width: 100%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  a {
    color: inherit;
  }

  #container {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  .fv {
    height: 100vh;
    width: 100%;
    @media screen and (max-width: 1000px) {
      height: 300vh;
      width: 70%;
    }
  }

  .heading {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    bottom: 0;
    padding: 1.5em 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 99;
  }

  .heading h1 {
    font-size: 3rem;
  }

  .heading a {
    font-size: 0.889rem;
    text-transform: capitalize;
    text-decoration: none;
    padding: 0.555em 1.5em;
    display: block;
    margin-top: 0.5em;
    border-radius: 2.5em;
    background-color: #fff;
    color: #1f1f1f;
    transition: 0.25s transform;
    position: relative;
  }

  .heading a:hover {
    transform: scale(1.075);
    position: relative;
  }

  .bg {
    background-image: url(https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80);
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .fv_img1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin-top: 0 !important;
    position: relative;
    @media screen and (max-width: 1000px) {
      position: relative;
      height: 100%;
    }
  }

  .ukiyo {
    margin-top: 12.5em;
    position: relative;
  }

  .img-size-1 {
    margin-left: 1%;
    position: relative;
  }

  .img-position-r {
    margin-left: auto;
    margin-right: 3%;
    position: relative;
  }

  .thirdPicture {
    height: 30%;
    width: 30%;
    margin-left: 60%;
    margin-top: 10%;
    @media screen and (max-width: 1000px) {
      height: 70%;
      width: 70%;
      margin-left: 54%;
      margin-top: 20%;
    }
  }

  .img-size-full {
    width: 100%;
    height: 1%;
    position: relative;
    object-fit: fill;

    @media screen and (max-width: 1000px) {
      min-width: 70%;
      height: 100%;
    }
  }

  .img-size-1 {
    width: 33.3333%;
    height: 75vh;
    position: relative;
    @media screen and (max-width: 1000px) {
      img {
        height: 1200%;
        width: 50%;
      }
    }
  }

  .img-size-2 {
    width: 50%;
    height: 120vh;
    position: relative;
  }

  footer {
    padding: 15em 5% 10em;
    opacity: 0.5;
    position: relative;
  }

  .first-picture {
    @media screen and (max-width: 1000px) {
      height: 65%;
      width: 65%;
      margin-left: -12%;
    }
  }

  .first-text {
    position: absolute;
    margin-left: 50vw;
    margin-top: -80vh;
    font-size: 25px;
    color: #d5d0d0;
    max-width: 500px;
    white-space: pre-line;
    font-family: Merriweather-LightItalic;
    background-color: #7a777758;
    @media screen and (max-width: 1000px) {
      position: relative;
      font-size: 80px;
      margin-left: 54%;
      min-width: 1300px;
      margin-top: -67%;
      margin-bottom: 20%;
    }
  }
  .title1 {
    margin-left: 40vw;
    position: absolute;
    color: #d5d0d0;
    background-color: #7a777758;
    font-family: Merriweather-LightItalic;
    @media screen and (max-width: 1000px) {
      font-size: 100px;
      margin-bottom: -10%;
      margin-top: -10%;
    }
  }

  .divtitle1 {
    @media screen and (max-width: 1000px) {
      margin-bottom: 10%;
      margin-top: 10%;
      position: relative;
    }
  }

  .second-text {
    position: relative;
    margin-left: 2vw;
    margin-top: 20vh;
    font-size: 25px;
    color: #d5d0d0;
    background-color: #7a777758;
    white-space: pre-line;
    font-family: Merriweather-LightItalic;

    margin-bottom: 10vh;
    @media screen and (max-width: 1000px) {
      font-size: 80px;
      margin-bottom: 30vh;
      max-width: 2500px;
      margin-left: 5%;
      margin-top: 20%;
    }
  }
  .third-text {
    position: relative;
    margin-top: -80vh;
    max-width: 500px;
    margin-left: 10vw;
    font-size: 25px;
    color: #d5d0d0;
    background-color: #7a777758;
    white-space: pre-line;
    font-family: Merriweather-LightItalic;

    margin-bottom: 50vh;
    @media screen and (max-width: 1000px) {
      font-size: 80px;
      max-width: 1200px;
      margin-left: 2vw;
      margin-top: -95%;
    }
  }
`;

const Sidemenu = styled.div`
  position: absolute;
  @media screen and (max-width: 1000px) {
    zoom: 310%;
    margin-top: 10%;
  }
  z-index: 99;
  margin: 0;
  padding: 0;
  border: 0;
  width: 200%;
  height: 100%;
  font-family: Merriweather-Light;
  color: rgb(79, 41, 6);

  .menu {
    .menu-content {
      -webkit-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -ms-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      float: left;
      width: 12em;
      height: 50%;
      width: 150px;
      margin-top: 3em;

      h2 {
        font-family: Merriweather-Light;
        font-weight: normal;
        margin-left: 1em;
        color: black;
        margin-left: 38px;
        border-bottom: 1px solid black;
      }
      ul {
        list-style: none;
        max-height: 200px;
      }
      ul li:hover {
        font-size: 25px;
      }
    }

    li {
      font-size: 23px;
      font-family: Merriweather-Light;
      margin-bottom: 10px;
      font-weight: normal;
      color: rgb(79, 41, 6) !important;
    }

    li,
    label {
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      -webkit-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -ms-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      &:hover {
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -ms-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        color: rgba(10, 10, 10, 0.9);
      }
    }
    .menu-switch {
      padding-top: 6em;
      label {
        font-size: 3em;
        font-family: "Open Sans Condensed", sans-serif;
      }
    }
    input {
      display: none;
    }
    #menu-collapsed {
      &:checked {
        ~ .menu-content {
          margin-left: -13em;
        }
        ~ .menu-switch .rise {
          display: block;
        }
        ~ .menu-switch .collapse {
          display: none;
        }
      }
      ~ .menu-switch .rise {
        display: none;
      }
    }
  }
`;

const SongListBrollop = styled.div`
  @media screen and (max-width: 1000px) {
    margin-top: -10vh;
    width: 70%;
  }
  z-index: 99;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -22vh;
  height: 70vh;
  position: absolute;
  margin-left: 25.8%;
  width: 45vw;
  overflow: auto;
  overflow-x: hidden;
  border: 1px solid black;
  border-radius: 32px;
  font-style: oblique 2deg;
  font-family: Merriweather-Italic;
  font-weight: 100;
  color: white;
  font-size: 22px;
  background: rgb(162, 97, 23);
  background: linear-gradient(
    38deg,
    rgba(162, 97, 23, 1) 8%,
    rgba(162, 101, 31, 1) 34%,
    rgba(215, 161, 107, 1) 82%,
    rgba(227, 172, 133, 1) 93%
  );
  opacity: 0.7;

  p {
    margin-left: 6vw;
  }
`;