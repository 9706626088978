import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import auth from "../services/auth";
import { useNavigate, Link } from "react-router-dom";
import AnimatedPage from "../AnimatedPage";
import "animate.css/animate.min.css";

function Brollop() {
  const [pictureList, setpictureList] = useState([]);
  const [picture, setPicture] = useState("");
  const [itemsNone, setItemsNone] = useState([]);
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [user, setUser] = useState();
  const [view, setView] = useState(null);
  const [isMobile, setIsMobile] = useState("");
  const [timer, setTimer] = useState("");


  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getList();
    getList2();
    getUser();
    getFolder();
    handleResize();
    handleTimer()

  }, []);

  function handleResize() {
    if (window.screen.width < 1200) {
      return setIsMobile(true);
    } else {
      return setIsMobile(false);
    }
  }

  function handleTimer(){
    const interval = setInterval(() => {
      return setTimer(true)
    },2100)
  }

  async function getFolder() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/brollop"
    );
    const Picture = data.data.files.map((m) => {
      return m;
    });
    return setpictureList(Picture);
  }
  async function getList() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/orderbrollop"
    );
    return setItems(data[0].image);
  }
  async function getList2() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/ordersecondbrollop"
    );
    return setItems2(data[0].image);
  }
  async function getUser() {
    const user = await auth.getCurrentUser();
    if (user) return setUser(user);
  }

  function handleReset() {
    return setView(null);
  }
  function totalReset(){
    if(view){
    return setView(null)
 }
}
  const onLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  const handlePicture = (e) => {
    setPicture(e.target.files[0]);
  };

  const onChange = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("photo", picture);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    axios
      .post(
        "https://ellinornielsen-backend.onrender.com/api/brollop",
        formdata,
        config
      )
      .catch((error) => {
        console.log(error);
      });
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    )
      return;

    let add,
      active = items,
      complete = items2,
      none = itemsNone;

    if (result.source.droppableId === "Images") {
      add = active[result.source.index];
      active.splice(result.source.index, 1);
    } else if (result.source.droppableId === "Images2") {
      add = complete[result.source.index];
      complete.splice(result.source.index, 1);
    } else if (result.source.droppableId === "none") {
      add = none[result.source.index];
      none.splice(result.source.index, 1);
    }

    if (result.destination.droppableId === "Images") {
      active.splice(result.destination.index, 0, add);
    }
    if (result.destination.droppableId === "Images2") {
      complete.splice(result.destination.index, 0, add);
    }
    if (result.destination.droppableId === "none") {
      none.splice(result.destination.index, 0, add);
    }

    setItemsNone(none);
    setItems2(complete);
    setItems(active);

    return (
      axios.post(
        "https://ellinornielsen-backend.onrender.com/api/orderbrollop",
        active
      ),
      axios.post(
        "https://ellinornielsen-backend.onrender.com/api/ordersecondbrollop",
        complete
      )
    );
  }

  return (
    <Total onClick={()=>totalReset()}>
          {!timer&&<Jumping>
        <div className="bosse animate__animated animate__fadeOut">
          <h3 className="text"> Bröllop</h3>
        </div>
      </Jumping>}
      <div>
        {user && <Logout onClick={onLogout}>Logout</Logout>}
        <Sender>
          {user && (
            <form onSubmit={onChange}>
              <input onChange={handlePicture} type="file" />

              <button onClick={() => onChange}>Skicka</button>
            </form>
          )}

          {user && (
            <button onClick={() => setItemsNone(pictureList)}>
              Reset to before
            </button>
          )}
        </Sender>
        {user && (
          <Container>
            <Social>
              <div className="showhim1">
                <Link to="/spelningar" className="link">
                  <button className="show1">Spelningar</button>
                </Link>
              </div>
              <div className="showhim2">
                <Link to="/dop" className="link">
                  <button className="show2">Dop</button>
                </Link>
              </div>
              <div className="showhim3">
                <Link to="/brollop" className="link">
                  <button className="show3">Bröllop</button>
                </Link>
              </div>
              <div className="showhim4">
                <Link to="/begravning" className="link">
                  <button className="show4">Begravning</button>
                </Link>
              </div>
              <div className="showhim5">
                {!user && (
                  <Link to="/bokning" className="link">
                    <button className="show5">Boking</button>
                  </Link>
                )}
                {user && (
                  <Link to="/bokningar" className="link">
                    <button className="show5">Bokingar</button>
                  </Link>
                )}
              </div>
            </Social>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="none">
                {(provided) => (
                  <span
                    className="none"
                    id="none"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {provided.placeholder}
                    {itemsNone.map((img, index, array) => (
                      <span key={index}>
                        <StyledUl>
                          <Draggable
                            key={img["id"]}
                            draggableId={img["id"]}
                            index={index}
                          >
                            {(provided) => (
                              <StyledUl
                                className="none"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {img.mimeType == "image/png" && (
                                  <Picture1
                                    key={index}
                                    src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                    alt={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                  />
                                )}
                                {img.mimeType == "image/jpeg" && (
                                  <Picture1
                                    key={index}
                                    src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                    alt={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                  />
                                )}

                                {img.mimeType == "video/mp4" && (
                                  <Video
                                    width="300px"
                                    height="300px"
                                    controls="controls"
                                  >
                                    <source
                                      src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                      type="video/mp4"
                                    />
                                  </Video>
                                )}
                              </StyledUl>
                            )}
                          </Draggable>
                        </StyledUl>
                      </span>
                    ))}
                  </span>
                )}
              </Droppable>
              <Droppable droppableId="Images">
                {(provided) => (
                  <span
                    id="Container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {provided.placeholder}
                    {items.map((img, index, array) => (
                      <span key={index}>
                        <StyledUl>
                          <Draggable
                            key={img["id"]}
                            draggableId={img["id"]}
                            index={index}
                          >
                            {(provided) => (
                              <StyledUl
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {img.mimeType == "image/png" && (
                                  <Picture1
                                    key={index}
                                    src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                    alt={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                  />
                                )}
                                {img.mimeType == "image/jpeg" && (
                                  <Picture1
                                    key={index}
                                    src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                    alt={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                  />
                                )}

                                {img.mimeType == "video/mp4" && (
                                  <Video
                                    width="300px"
                                    height="300px"
                                    controls="controls"
                                  >
                                    <source
                                      src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                      type="video/mp4"
                                    />
                                  </Video>
                                )}
                              </StyledUl>
                            )}
                          </Draggable>
                        </StyledUl>
                      </span>
                    ))}
                  </span>
                )}
              </Droppable>

              <Droppable droppableId="Images2">
                {(provided) => (
                  <span
                    id="Container2"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {provided.placeholder}
                    {items2.map((img, index, array) => (
                      <span key={index}>
                        <StyledUl2>
                          <Draggable
                            key={img["id"]}
                            draggableId={img["id"]}
                            index={index}
                          >
                            {(provided) => (
                              <StyledUl2
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {img.mimeType == "image/png" && (
                                  <Picture1
                                    key={index}
                                    src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                    alt={img}
                                  />
                                )}
                                {img.mimeType == "image/jpeg" && (
                                  <Picture1
                                    key={index}
                                    src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                    alt={img}
                                  />
                                )}
                                {img.mimeType == "video/mp4" && (
                                  <Video
                                    width="300px"
                                    height="300px"
                                    controls="controls"
                                  >
                                    <source
                                      src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                                      type="video/mp4"
                                    />
                                  </Video>
                                )}
                              </StyledUl2>
                            )}
                          </Draggable>
                        </StyledUl2>
                      </span>
                    ))}
                  </span>
                )}
              </Droppable>
            </DragDropContext>
          </Container>
        )}
        {!user && (
          <Container2>
            {!isMobile && (
              <Social>
                <div className="showhim1">
                  <Link to="/spelningar" className="link">
                    <button className="show1">Spelningar</button>
                  </Link>
                </div>
                <div className="showhim2">
                  <Link to="/dop" className="link">
                    <button className="show2">Dop</button>
                  </Link>
                </div>
                <div className="showhim3">
                  <Link to="/brollop" className="link">
                    <button className="show3">Bröllop</button>
                  </Link>
                </div>
                <div className="showhim4">
                  <Link to="/begravning" className="link">
                    <button className="show4">Begravning</button>
                  </Link>
                </div>
                <div className="showhim5">
                  {!user && (
                    <Link to="/bokning" className="link">
                      <button className="show5">Boking</button>
                    </Link>
                  )}
                  {user && (
                    <Link to="/bokningar" className="link">
                      <button className="show5">Bokingar</button>
                    </Link>
                  )}
                </div>
              </Social>
            )}
            {isMobile && (
              <MobileMenu>
                <nav role="navigation">
                  <div id="menuToggle">
                    <input type="checkbox" />

                    <span></span>
                    <span></span>
                    <span></span>

                    <ul id="menu">
                      <a href="#">
                        <Link to="/" className="link">
                          <li>Spelningar</li>
                        </Link>
                      </a>
                      <a href="#">
                        <Link to="/dop" className="link">
                          <li>Dop</li>
                        </Link>
                      </a>
                      <a href="#">
                        <Link to="/brollop" className="link">
                          <li>Bröllop</li>
                        </Link>
                      </a>
                      <a href="#">
                        <Link to="/begravning" className="link">
                          <li>Begravning</li>
                        </Link>
                      </a>
                      <a href="#">
                        {!user && (
                          <Link to="/bokning" className="link">
                            <li>Bokning</li>
                          </Link>
                        )}
                        {user && (
                          <Link to="/bokningar" className="link">
                            <li>Bokningar</li>
                          </Link>
                        )}
                      </a>
                    </ul>
                  </div>
                </nav>
              </MobileMenu>
            )}
            {view && (
              <Selected>
                {<img onClick={() => handleReset()} src={view} />}
              </Selected>
            )}
            { (
              <div>
                {items.map((img, index, array) => (
                  <span key={index}>
                    <StyledUl>
                      {img.mimeType == "image/png" && (
                        <Picture1
                          onClick={() =>
                            setView(
                              `https://drive.google.com/uc?export=view&id=${img["id"]}`
                            ) + window.scrollTo(0, 150)
                          }
                          key={index}
                          src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                          alt={img}
                        />
                      )}
                      {img.mimeType == "image/jpeg" && (
                        <Picture1
                          onClick={() =>
                            setView(
                              `https://drive.google.com/uc?export=view&id=${img["id"]}`
                            ) + window.scrollTo(0, 150)
                          }
                          key={index}
                          src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                          alt={img}
                        />
                      )}

                      {img.mimeType == "video/mp4" && (
                        <Video width="300px" height="300px" controls="controls">
                          <source
                            src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                            type="video/mp4"
                          />
                        </Video>
                      )}
                    </StyledUl>
                  </span>
                ))}
              </div>
            )}
            {!view && (
              <div>
                {items2.map((img, index, array) => (
                  <span key={index}>
                    <StyledUl2>
                      {img.mimeType == "image/png" && (
                        <Picture1
                          onClick={() =>
                            setView(
                              `https://drive.google.com/uc?export=view&id=${img["id"]}`
                            ) + window.scrollTo(0, 150)
                          }
                          key={index}
                          src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                          alt={img}
                        />
                      )}
                      {img.mimeType == "image/jpeg" && (
                        <Picture1
                          onClick={() =>
                            setView(
                              `https://drive.google.com/uc?export=view&id=${img["id"]}`
                            ) + window.scrollTo(0, 150)
                          }
                          key={index}
                          src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                          alt={img}
                        />
                      )}
                      {img.mimeType == "video/mp4" && (
                        <Video width="300px" height="300px" controls="controls">
                          <source
                            src={`https://drive.google.com/uc?export=view&id=${img["id"]}`}
                            type="video/mp4"
                          />
                        </Video>
                      )}
                    </StyledUl2>
                  </span>
                ))}
              </div>
            )}
          </Container2>
        )}
      </div>
    </Total>
  );
}
export default Brollop;

const Total=styled.div`

`
const Jumping=styled.div`
position: relative;
display: grid;
place-content: center;


.text{
  color: white;
  margin-top:60vh;
  margin-left: 50%;
  font-family:'Brush Script MT, Brush Script Std, cursive';
  font-style: italic;
  @media screen and (max-width: 1000px) {
    margin-left:40vw;
    font-size:100px;
    margin-top: 40vh;

    }

}
.bosse{
  background-color: black;
height: 3000px;
width: 3000px;
margin-top: -400px;
animation-duration: 3s;
@media screen and (max-width: 1000px) {
  
  height: 200vh;
  width: 120vw;
  }

}
`

const Picture1 = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0 0 50px gray;
  background-color: white;
  position: relative;
  :hover {
    transition: 1s;
    height: 320px;
    width: 320px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
  :not(:hover) {
    transition: 1s;
    height: 300px;
    width: 300px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
`;
const Picture2 = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0 0 50px gray;
  background-color: white;
  position: relative;
  :hover {
    transition: 1s;
    height: 320px;
    width: 320px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
  :not(:hover) {
    transition: 1s;
    height: 300px;
    width: 300px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
`;

const Container = styled.div`
  height: 200px;
  display: grid;
  margin-right: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  @media screen and (max-width: 1000px) {
    margin-left:80px;

    }
  .none {
    border: 2px solid black;
  }
`;
const Container2 = styled.div`
  height: 200px;
  display: grid;
  margin-right: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  @media screen and (max-width: 1000px) {
    margin-left:80px;

    }

  img {
    margin: 30px;
  }
  video {
    margin: 30px;
  }
  .noAdmin {
    img {
      margin-bottom: 200px;
      height: 300px;
      width: 300px;
      @media screen and (max-width: 491px) {
        width: 200px;
        height: 200px;
      }
    }
    video {
      margin-bottom: 200px;
      height: 300px;
      width: 300px;
      @media screen and (max-width: 491px) {
        width: 200px;
        height: 200px;
      }
    }
  }
`;

const StyledUl = styled.ul`
  /* height: 200px;
  width: 100px; */
  position: relative;
`;
const StyledUl2 = styled.ul`
  /* height: 200px;
  width: 100px; */
  position: relative;
`;

const Video = styled.video`
  width: 300px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0 0 50px gray;
  background-color: white;
  position: relative;
  :hover {
    transition: 1s;
    height: 320px;
    width: 320px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
  :not(:hover) {
    transition: 1s;
    height: 300px;
    width: 300px;
    @media screen and (max-width: 491px) {
      width: 200px;
      height: 200px;
    }
  }
`;

const Logout = styled.button`
  height: 30px;
  width: 120px;
  display: flex;
  justify-content: center;
  float: right;
  position: relative;
  box-shadow: 0 0 12px gray;
  background-color: white;
  color: lightslategray;
  font-size: 20px;
  margin-top: -150px;
`;
const Sender = styled.div`
  height: 30px;
  width: 250px;
  display: grid;
  justify-content: center;
  float: right;
  position: relative;
  background-color: white;
  color: black;
  margin-top: -100px;
  margin-left: 50px;
  font-size: 20px;
  background-color: transparent;
  border: 0px;
  @media screen and (max-width: 391px) {
    margin-top: -110px;
    margin-right: 10px;
  }
  button {
    border-radius: 32px;
    margin-bottom: 2px;
    margin-top: 15px;
  }
`;
const Social = styled.div`
  margin-left: 40px;
  margin-top: 20px;
  position: relative;


  .link {
    cursor: auto;
  }

  .show1 {
    height: 30px;
    width: 180px;
    margin-top: 15px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 0 12px gray;
    background-color: transparent;
    color: lightslategray;
    font-size: 20px;
    position: fixed;
    font-family:'Brush Script MT, Brush Script Std, cursive';
    font-style: italic;
    @media screen and (max-width: 1000px) {
      height: 90px;
      width: 400px;
      font: 150px;
    }

    :hover {
      transition: 1s;
      height: 40px;
      width: 200px;
      margin-top: 5px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
    :not(:hover) {
      transition: 1s;
      width: 180px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
  }

  .showhim1:hover .showme1 {
    transition: 2s;
    opacity: 1;
    margin-left: 20px;
  }
  .showhim1:not(:hover) .showme1 {
    transition: 1s;
    opacity: 0;
    margin-left: -20px;
  }
  .show2 {
    height: 30px;
    width: 180px;
    margin-top: 15px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 0 12px gray;
    background-color: transparent;
    color: lightslategray;
    font-size: 20px;
    position: fixed;
    font-family:'Brush Script MT, Brush Script Std, cursive';
    font-style: italic;
    @media screen and (max-width: 1000px) {
      height: 90px;
      width: 400px;
      font: 150px;
    }

    :hover {
      transition: 1s;
      height: 40px;
      width: 200px;
      margin-left: 10px;
      margin-top: 10px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
    :not(:hover) {
      transition: 1s;
      width: 180px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
  }

  .showhim2:hover .showme2 {
    transition: 2s;
    opacity: 1;
    margin-left: 20px;
  }
  .showhim2:not(:hover) .showme2 {
    transition: 1s;
    opacity: 0;
    margin-left: -30px;
  }
  .show3 {
    height: 30px;
    width: 180px;
    margin-top: 15px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 0 12px gray;
    background-color: transparent;
    color: lightslategray;
    font-size: 20px;
    position: fixed;
    font-family:'Brush Script MT, Brush Script Std, cursive';
    font-style: italic;
    @media screen and (max-width: 1000px) {
      height: 90px;
      width: 400px;
      font: 150px;
    }

    :hover {
      transition: 1s;
      height: 40px;
      width: 200px;
      margin-left: 10px;
      margin-top: 10px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
    :not(:hover) {
      transition: 1s;
      width: 180px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
  }

  .showhim3:hover .showme3 {
    transition: 2s;
    opacity: 1;
    margin-left: 20px;
  }
  .showhim3:not(:hover) .showme3 {
    transition: 1s;
    opacity: 0;
    margin-left: -30px;
  }
  .show4 {
    height: 30px;
    width: 180px;
    margin-top: 15px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 0 12px gray;
    background-color: transparent;
    color: lightslategray;
    font-size: 20px;
    position: fixed;
    font-family:'Brush Script MT, Brush Script Std, cursive';
    font-style: italic;
    @media screen and (max-width: 1000px) {
      height: 90px;
      width: 400px;
      font: 150px;
    }

    :hover {
      transition: 1s;
      height: 40px;
      width: 200px;
      margin-left: 10px;
      margin-top: 10px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
    :not(:hover) {
      transition: 1s;
      width: 180px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
  }

  .showhim4:hover .showme4 {
    transition: 2s;
    opacity: 1;
    margin-left: 20px;
  }
  .showhim4:not(:hover) .showme4 {
    transition: 1s;
    opacity: 0;
    margin-left: -30px;
  }
  .show5 {
    height: 30px;
    width: 180px;
    margin-top: 15px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 0 12px gray;
    background-color: transparent;
    color: lightslategray;
    font-size: 20px;
    position: fixed;
    font-family:'Brush Script MT, Brush Script Std, cursive';
    font-style: italic;
    @media screen and (max-width: 1000px) {
      height: 90px;
      width: 400px;
      font: 150px;
    }

    :hover {
      transition: 1s;
      height: 40px;
      width: 200px;
      margin-top: 10px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
    :not(:hover) {
      transition: 1s;
      width: 180px;
      @media screen and (max-width: 1000px) {
        height: 90px;
        width: 400px;
        font: 150px;
      }
    }
  }

  .showhim5:hover .showme5 {
    transition: 2s;
    opacity: 1;
    margin-left: 20px;
  }
  .showhim5:not(:hover) .showme5 {
    transition: 1s;
    opacity: 0;
    margin-left: -30px;
  }

  div {
    display: grid;
    height: 50px;
    width: 300px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media screen and (max-width: 1000px) {
      height: 100px;
    }
  }
`;
const Selected = styled.div`
  position: relative;
  height: 700px;
  width: 700px;
  left:-10%;
  top:-20%;
  margin-right:50vw;
  margin-left: 20%;


  img {
    height: 100%;
    width: 100%;
    box-shadow: 0 0 50px black;
  }
  @media screen and (max-width: 1000px) {
    width: 150vw;
    height: auto;
    max-width: none;
    max-height: 100vh;
    object-fit: contain;
    margin-top: 30vh;
    margin-left:200px;

  }
`;

const MobileMenu = styled.div`
  body {
    margin: 0;
    padding: 0;

    /* make it look decent enough */
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle {
    display: block;
    position: fixed;
    top: 350px;
    left: 50px;
    margin-right:10vw;


    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 120px;
    height: 102px;
    position: absolute;
    top: -7px;
    left: -22px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 82px;
    height: 12px;
    margin-bottom: 15px;
    position: relative;

    background: #737373;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(1px, 1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 180vw;
    height: 200vh;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 52px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }
`;
